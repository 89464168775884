import React, { useContext, useRef, useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { get, post } from 'aws-amplify/api';
import { produce } from 'immer';
import { locsEqual, generateBlackouts, removeLoc, pickHumanBlackouts, getRotationallySymmetricLoc, locInList, getAllOuterBlackouts } from '../../libs/blackoutsLib';
import { emptyCharGrid, PuzzleMetadataKey, SlotStructure, getCharGridDifferences, SymmetryType } from '../../libs/directionsLib';
import { fromPuzzleContentObject, toPuzzleContentObject } from '../../libs/exportLib';
import { AUTOFILL_STATUS, getSlotFillOptions, isGridWorthAutofilling, modifiedSlotFillOptions, startAutofill, stopAutofillAndWordSuggestor, stopAutofillOnly, useWordsMap } from '../../libs/autofillLib';
import { BoardStyle, CellStyleName } from '../../libs/formatLib';
import { ChangeEventGroup, getSlotIndexFromSlotName, mergePuzzleWithChangeEvents, newClueChangeEvent, newClueOverhaulChangeEvent, newFurnishingChangeEvent,
  newGridChangeEvent, newMetadataChangeEvent, newOverhaulChangeEvent, redoneChangeEventGroups, undoneChangeEventGroups } from '../../libs/changeEventLib';
import { broadcastCursorMove, closeWsConnection, openNewWsConnection, submitChangeEventsToServer } from '../../libs/webSocketLib';
import { FurnishingType, emptyFurnishingsObject, furnishingsObjectToList, newCircleFurnishing, newColorFurnishing, newInvisibilityFurnishing } from '../../libs/furnishingsLib';
import { useCursorStates } from '../../libs/cursorLib';
import { DEFAULT_CHAR_GRID, DEFAULT_CLUES, DEFAULT_FURNISHINGS, DEFAULT_PUZZLE_METADATA, DEFAULT_SLOT_STRUCTURE } from '../../libs/puzzleLib';
import { useAppContext } from '../../App';
import { useToastNotifications } from '../../libs/toastLib';
import { MdForkRight } from 'react-icons/md';


/** A list of available puzzle preferences to set. These include things like construction preferences. 
 * These are BoardInteractionContext-specific, unlike the other data like puzzle metadata, etc.
*/
export const PuzzlePreferenceKey = {
  BLACKOUTS_PRESERVE_WORDS: 'blackoutsPreserveWords',
  WARNING_STYLE_SHORT_WORDS: 'warningStyleShortWords',
  WARNING_STYLE_DUPLICATE_WORDS: 'warningStyleDuplicateWords',
  WARNING_STYLE_NO_SUGGESTIONS: 'warningStyleNoSuggestions',
  MINIMUM_WORD_SCORE: 'minWordScore',
};
const DEFAULT_PUZZLE_PREFERENCES = new Map([   // puzzle preferences are not preserved with the puzzle like puzzle metadata
  [PuzzlePreferenceKey.BLACKOUTS_PRESERVE_WORDS, true],
  [PuzzlePreferenceKey.WARNING_STYLE_SHORT_WORDS, CellStyleName.ERROR],
  [PuzzlePreferenceKey.WARNING_STYLE_DUPLICATE_WORDS, CellStyleName.ERROR],
  [PuzzlePreferenceKey.WARNING_STYLE_NO_SUGGESTIONS, CellStyleName.WARNING],
  [PuzzlePreferenceKey.MINIMUM_WORD_SCORE, 30],
]);


/** The different status options for a puzzle's interaction. */
export const PuzzleInteractionStatus = {
  EDITABLE: 'editable', // user has free access to edit and save the board, clues, and metadata
  EDITABLE_GUEST: 'editable_guest', // editable as a guest, i.e. not savable
  THINKING: 'thinking', // user cannot interact with puzzle and the board should display some waiting animation
  STATUESQUE: 'statuesque', // the board looks normal but does not respond to clicking, hovering, etc.
};


// Define contexts available to children
const PuzzleIdContext = React.createContext();        // the puzzleId, e.g. for child components to use for their own API calls (such as sharing the puzzle)

const CursorContext = React.createContext();          // an object containing the cursorLoc and the cursorDirection, as well as methods to interact with them

const CharGridContext = React.createContext();            // the board's source of truth: a 2D array of empty strings, characters, or 'blackout' markers
const FurnishingsContext = React.createContext();        // additional user-defined "furnishings" on the grid, such as custom colors
const UpdateCharGridAtLocsContext = React.createContext(); // a function that updates the character at a given charGrid loc, respecting symmetry if needed
const ChangeCharGridDimensionsContext = React.createContext(); // a function that clears the grid and updates its number of rows/cols
const SlotStructureContext = React.createContext();     // the SlotStructure object (in sync with the charGrid)
const SlotFillOptionsContext = React.createContext();    // the slotFillOptions Map (in sync with the charGrid)
const RequestNewBlackoutsContext = React.createContext();  // the function to request new blackouts generation
const GhostCharsContext = React.createContext();  // functions exposing the tentative, "ghost" suggestions that aren't part of the charGrid yet
const AutofillContext = React.createContext();   // function to request autofill
const WordSuggestorContext = React.createContext();  // word suggestor cache and wordsMap

const CluesContext = React.createContext();                 // objects / functions to access clues

const PuzzlePreferencesContext = React.createContext();   // a few different functions involving puzzle preferences, which are not inherently associated with the puzzle

const PuzzleMetadataContext = React.createContext();      // objects/functions to access puzzle metadata

const BoardStyleContext = React.createContext();           // the BoardStyle object that defines the colors etc. of the cells in the grid

const SavePuzzleContext = React.createContext();        // a function that saves the puzzle to DynamoDB

const PuzzleInteractionStatusContext = React.createContext();  // the status of puzzle interaction, i.e. editable, beachballing, etc...

const PuzzleVersioningContext = React.createContext();   // functions to request "undo" and "redo" of board edits

const LiveModeContext = React.createContext();    // exposure to live/collab mode functionality


// Define hooks for each of these contexts
export function usePuzzleId() {
  return useContext(PuzzleIdContext);
}

export function useCursor() {
  return useContext(CursorContext);
}

export function useCharGrid() {
  return useContext(CharGridContext);
}
export function useFurnishings() {
  return useContext(FurnishingsContext);
}
export function useUpdateCharGridAtLocs() {
  return useContext(UpdateCharGridAtLocsContext);
}
export function useChangeCharGridDimensions() {
  return useContext(ChangeCharGridDimensionsContext);
}
export function useSlotStructure() {
  return useContext(SlotStructureContext);
}
export function useSlotFillOptions() {
  return useContext(SlotFillOptionsContext);
}
export function useRequestNewBlackouts() {
  return useContext(RequestNewBlackoutsContext);
}
export function useAutofill() {
  return useContext(AutofillContext);
}
export function useWordSuggestor() {
  return useContext(WordSuggestorContext);
}
export function useGhostChars() {
  return useContext(GhostCharsContext);
}

export function useClues() {
  return useContext(CluesContext);
}

export function usePuzzlePreferences() {
  return useContext(PuzzlePreferencesContext);
}

export function usePuzzleMetadata() {
  return useContext(PuzzleMetadataContext);
}

export function useBoardStyle() {
  return useContext(BoardStyleContext);
}

export function useSavePuzzle() {
  return useContext(SavePuzzleContext);
}

export function usePuzzleInteractionStatus() {
  return useContext(PuzzleInteractionStatusContext);
}

export function usePuzzleVersioning() {
  return useContext(PuzzleVersioningContext);
}

export function useLiveMode() {
  return useContext(LiveModeContext);
}


var checkForConflictsTimeout = null; // identifier for the timeout in checkForConflicts() to debounce that function


export default function BoardInteractionProvider({ initialPuzzleItem, gridOnly=false, children }) {

  // App context
  const { isAuthenticated } = useAppContext();
  const { postNotification, postErrorNotification, removeNotificationsWithLabels } = useToastNotifications();

  const isScratch = !Boolean(initialPuzzleItem);

  // puzzleId and the baseline permissions level may be treated uniquely because they are constant (or require a page refresh to change)
  const puzzleId = isScratch ? null : initialPuzzleItem.puzzleId;
  const baselinePuzzleInteractionStatus = isScratch || gridOnly ? PuzzleInteractionStatus.EDITABLE_GUEST : (
    initialPuzzleItem.permissionsLevel === 'EDIT' || initialPuzzleItem.permissionsLevel === 'MANAGE' ? PuzzleInteractionStatus.EDITABLE : PuzzleInteractionStatus.STATUESQUE
  );


  /**
   * Define state variables. Because I am observing lags due to too many state variables being updated simultaneously (forcing many redundant re-renders),
   * I'm collapsing many of this BoardInteractionContext's state values into a Ref object. Then, I'm exposing "set___" functions as if they were genuine state values.
   * In each of these setter functions, there's an optional second parameter "triggerRender" which is true by default, so the setter may be used as if it were a normal set-state function.
   * If "triggerRender" is given as false, the setter will update the ref value, but NOT trigger a re-render. This is only intended to be used when multiple setters are called simultaneously.
   * 
   * Initial values, if they're provided via initialPuzzleContent, are set below in the useEffect hook.
   */
  // eslint-disable-next-line no-unused-vars
  const [renderTrigger, setRenderTrigger] = useState(0);   // increments by 1 to trigger a re-render
  // Replacement for useState
  function useStateRef(initialValue) {
    const stateRef = useRef(initialValue);

    return [stateRef.current, useCallback((newValue, triggerRender = true) => {
      if (newValue instanceof Function) {
        stateRef.current = newValue(stateRef.current);
      } else {
        stateRef.current = newValue;
      }

      if (triggerRender) {
        setRenderTrigger(rt => rt + 1);
      }
    }, [])];
  }




  // THE BIG FOUR: these states are enough to define the puzzle as it's saved in the database.
  const [charGrid, setCharGrid] = useStateRef(DEFAULT_CHAR_GRID);   // see note below about slotStructure and slotFillOptions!
  const [furnishings, setFurnishings] = useStateRef(DEFAULT_FURNISHINGS);
  const [clues, setClues] = useStateRef(DEFAULT_CLUES);
  const [puzzleMetadata, setPuzzleMetadata] = useStateRef(DEFAULT_PUZZLE_METADATA);

  // Convenience
  const numRows = charGrid.length;
  const numCols = charGrid[0].length;

  // For quick identification of slots for autofill/suggestions, maintain a record of all the slots and all their fill options
  // slotStructure: a container object holding a map from slotName to slot object (direction, locs, chars, crossSlotNames, crossIndexes)
  // slotFillOptions: a map from slotName to null (if that slot hasn't been calculated yet) or to a list of strings
  // These things MUST be set every time charGrid is set!
  const [slotStructure, setSlotStructure] = useStateRef(DEFAULT_SLOT_STRUCTURE);
  const [slotFillOptions, setSlotFillOptions] = useStateRef(null);  // this one must be set after first render (i.e. after loading)
  const [boardStyle, setBoardStyle] = useStateRef(BoardStyle.default());

  // Value containing the status of autofill (one of the AUTOFILL_STATUS strings)
  const [autofillStatus, setAutofillStatusState] = useStateRef(null);
  const autofillStatusRef = useRef(null);    // we also have a ref that gets the same value as the state because some callbacks need it as a ref
  function setAutofillStatus(newStatus) {    // create a custom setter that will also update the ref (I feel like it's faster than useEffect)
    setAutofillStatusState(newStatus);
    autofillStatusRef.current = newStatus;
  }

  // Whether or not autofill happens continuously (user decided)
  const [continuouslyAutofill, setContinuouslyAutofill] = useStateRef(true);
  
  // A suggested fill based on the autofill/wordSuggestor functionality
  const [suggestedFill, setSuggestedFill_] = useStateRef(null);   // { locsToChange, newChars, essentialLocs } or null
  function setSuggestedFill(newVal) {
    setSuggestedFill_(newVal);
    if (newVal) {
      /* Because this callback may occur after the grid has changed, we may run into some occasional errors while trying to update the wordsMap.
         I actually think this happens when collaborators are live-changing the grid simultaneously. This try/catch is my perhaps-temporary-perhaps-permanent fix. */
      try {
        addKnownFillToWordsMap(newVal);
      } catch (e) {
        console.log('Note: could not add known fill to wordsMap');
        console.log(e);
      }
    }
  }
  
  // Word suggestor cache and wordsMap
  function onSuccessfulSuggestion(slotName, word, fill) {
    // This function will be called after returning from a web worker, so we can't rely on the state values to be accurate. Need to use a ref
    if (autofillStatusRef.current === AUTOFILL_STATUS.SEARCHING) stopAutofillOnly();   // cancel the autofill if we've already found a fill!
    if (autofillStatusRef.current !== AUTOFILL_STATUS.SUCCEEDED) {
      // Add the locs in the slot that aren't already filled in
      const { locsToChange, newChars, essentialLocs } = fill;
      const idxsToAdd = slotStructure.getCharsInSlot(slotName)?.map((c, idx) => c === '' ? idx : '')?.filter(String);
      if (!idxsToAdd) return;   // I'm still not sure why, but I was getting a not-so-reproducible bug every now and then without this
      setSuggestedFill({
        locsToChange: locsToChange.concat(idxsToAdd.map(i => slotStructure.getLocsInSlot(slotName)[i])),
        newChars: newChars.concat(idxsToAdd.map(i => word.toUpperCase().split('')[i])),
        essentialLocs,
      });
      setAutofillStatus(AUTOFILL_STATUS.SUCCEEDED);
    }
  }
  const { 
    wordSuggestorCache, wordsMap, 
    updateCharGridForWordSuggestor, updateCursorForWordSuggestor, 
    updateMinScoreForWordSuggestor,
    turnOnWordSuggestor, turnOffWordSuggestor,
    displayMoreWords, 
    isCurrentlyEvaluatingMore,
    addKnownFillToWordsMap, declareFillImpossible,
  } = useWordsMap(onSuccessfulSuggestion);

  useEffect(() => {
    if (gridOnly)
      turnOffWordSuggestor();
  }, [gridOnly, turnOffWordSuggestor]);

  // The cursor information isn't saved to the database, but it does affect screen display and is transmitted to other collaborators
  const { cursorLoc, cursorDirection, setCursorLoc, setCursorDirection, handleClickOnLoc, handleCursorDirective } = useCursorStates({
    charGrid,
    cursorHasChanged: (newLoc, newDirection) => {
      // Update wordSuggestor
      updateCursorForWordSuggestor(newLoc, newDirection);

      // Callback to broadcast each cursor change when in live mode
      if (inLiveMode) {
        broadcastCursorMove(newLoc);
      } else if (liveModeIsTimedOut) {
        enterLiveMode();
      } else if (!liveModeIsConnecting) {   // if in the process of establishing a connection, don't do anything
        checkForConflicts();   // if not in live mode, use cursor changing as an opportunity to check for conflicts, since it signals the user is active
      }
    }
  });
  const currentSlotName = cursorLoc && charGrid[cursorLoc[0]][cursorLoc[1]] !== 'blackout' ? slotStructure.getSlotNameContainingCoordinates(cursorLoc, cursorDirection) : null;
  
  // Convert suggestedFill into formatted ghostChars that the board can understand
  // It's a list of locs and chars that aren't part of the charGrid but should show up as "suggested" or tentative; [{ loc, char, ghostLevel: (1,2,3) }]
  const ghostChars = suggestedFill?.locsToChange?.map((loc, idx) => ({
    loc,
    char: suggestedFill.newChars[idx],
    ghostLevel: locInList(loc, suggestedFill.essentialLocs) ? 3 : 1,
  })) || [];
  // If the currently-selected slot intersects any level-1 ghostChars, bump them up to level-2 for the sake of visibility (it can be quite faint)
  if (currentSlotName) {
    const currentSlotLocs = slotStructure.getLocsInSlot(currentSlotName);
    ghostChars.forEach(gc => {
      if (locInList(gc.loc, currentSlotLocs) && gc.ghostLevel === 1) {
        gc.ghostLevel = 2;
      }
    })
  }


  // Puzzle preferences exist outside undo/redo or save functionality - e.g. error message styles
  const [puzzlePreferences, setPuzzlePreferences] = useState(DEFAULT_PUZZLE_PREFERENCES);
  // Function that should be used to set the minimum word score preference, since it also updates the word suggestor
  function setMinScore(minScore) {
    setPuzzlePreference(PuzzlePreferenceKey.MINIMUM_WORD_SCORE, minScore);
    updateMinScoreForWordSuggestor(minScore);    // update word suggestor
    setAutofillStatus(null);    // reset autofill as well
    tryContinuousAutofill(slotStructure, undefined, minScore);
  }

  // States to define whether the user can interact with the puzzle at that moment
  const [puzzleInteractionStatus, setPuzzleInteractionStatus] = useStateRef(baselinePuzzleInteractionStatus);
  const [isSaved, setIsSaved] = useStateRef(true);
  const [isSaving, setIsSaving] = useStateRef(false);

  // Live mode: auto-saves all changes to database and accepts incoming changes from collaborators, via web socket
  const [inLiveMode, setInLiveMode] = useStateRef(false);
  const [liveModeIsConnecting, setLiveModeIsConnecting] = useStateRef(false);
  const [liveModeIsTimedOut, setLiveModeIsTimedOut] = useStateRef(false);  // when not in live mode, is it because it timed out due to user inactivity?
  const [liveConnectionInfo, setLiveConnectionInfo] = useStateRef(null);   // native object from publicConnectionId: { displayName, cursorColor, cursorLoc }

  // Periodic conflict-check (non-live mode; checking if the puzzle has changed by someone else since our last save)
  const [serverSideModifiedAt, setServerSideModifiedAt] = useStateRef(initialPuzzleItem?.modifiedAt);
  const [lastConflictCheckTimestamp, setLastConflictCheckTimestamp] = useStateRef(0);  // will be of format Date.now()

  // The current status message as displayed to the user; e.g. "All changes saved"
  const [currentStatusMessage, setCurrentStatusMessage] = useStateRef('Hello there!');
  function resetCurrentStatusMessage() {   // called when the status message should be reset to "normal" (unsaved)
    setCurrentStatusMessage(inLiveMode ? 'Saving...' : 'A masterpiece in the works!');
  }

  // Set up a timer to periodically check if the word database has loaded and set slotFillOptions if so
  useEffect(() => {
    var loadWordDbTimeout;
    if (slotFillOptions === null) {
      const checkForWordDatabaseLoad = () => {
        const sfo = getSlotFillOptions(slotStructure);
        if (sfo !== null) {
          setSlotFillOptions(sfo);
        } else {
          loadWordDbTimeout = setTimeout(checkForWordDatabaseLoad, 100);
        }
      }

      checkForWordDatabaseLoad();

      return () => clearTimeout(loadWordDbTimeout);
    }
  }, [slotStructure, slotFillOptions, setSlotFillOptions]);

  // Change event queue: used to track all changes for undo/redo, as well as, during live mode, resolving with other collaborators' changes
  const changeEventHistoryRef = useRef([]);   // it's a list of ChangeEventGroups, i.e. a list of grouped (simultaneous) change events

  
  // Set initial state values on first render: easier to do logic in a useEffect
  useEffect(() => {
    if (!isScratch || gridOnly) {
      // Assign fundamental BoardInteractionContext state values based on their initial values
      const { puzzleContent, modifiedAt } = initialPuzzleItem;
      const { charGrid, furnishings, clues, puzzleMetadata } = fromPuzzleContentObject(puzzleContent);
      setCharGrid(charGrid);
      const ss = SlotStructure.buildNewSlotStructure(charGrid);
      const sfo = getSlotFillOptions(ss);
      setSlotStructure(ss);
      setSlotFillOptions(sfo);
      setBoardStyle(getBoardStyleFromSlotStructure(ss, sfo, DEFAULT_PUZZLE_PREFERENCES));
      setFurnishings(furnishings);
      setClues(clues);
      setPuzzleMetadata(puzzleMetadata);
      setIsSaved(true);
      setServerSideModifiedAt(modifiedAt);

      // Update the word suggestor
      updateCharGridForWordSuggestor(charGrid, ss, sfo);
      updateMinScoreForWordSuggestor(DEFAULT_PUZZLE_PREFERENCES.get(PuzzlePreferenceKey.MINIMUM_WORD_SCORE));

      // Start live mode editing!
      if (!gridOnly && (initialPuzzleItem?.permissionsLevel === 'EDIT' || initialPuzzleItem?.permissionsLevel === 'MANAGE')) enterLiveMode();
    }   // else if the user is in the scratch space, the default state values will suffice (empty grid, etc)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScratch, initialPuzzleItem]);



  /**
   * Checks for conflicts in a throttled manner. Recommended to be called anytime there's activity in the puzzle, i.e. anytime the cursor moves.
   */
  function checkForConflicts() {
    if (!isScratch && !gridOnly && !inLiveMode && !isSaving && lastConflictCheckTimestamp < Date.now() - 6000) {
      clearTimeout(checkForConflictsTimeout);

      checkForConflictsTimeout = setTimeout(async () => {
        try {
          const response = await get({
            apiName: 'userPuzzles',
            path: `/userPuzzles/${puzzleId}`,
            options: { headers: { 'x-getspecs': 'conflictcheckonly' } }
          }).response;
          const { modifiedAt, knownConnections } = await response.body.json();

          if (!serverSideModifiedAt) {
            // If the user entered then exited live mode, serverSideModifiedAt won't be accurate, so just reset it from now... this leaves open the possibility for uncaught conflicts
            setServerSideModifiedAt(modifiedAt);
          } else if (modifiedAt !== serverSideModifiedAt) {
            postNotification({
                variant: 'angry',
                icon: <MdForkRight />,
                headline: 'Enter live mode!',
                content: <span>
                  Looks like another window, tab, or collaborator has edited this puzzle since you last saved. Saving now will overwrite their work. 
                  You should <a href={`/construct/${puzzleId}`} onClick={e => {
                  e.preventDefault();
                  enterLiveMode();
                }}>join live mode</a> instead to get their new changes. If you'd like to preserve your edits alone, use File &gt; Duplicate to save a copy.</span>,
                labels: ['request-live-mode', 'request-save-as', 'puzzle-specific'],
            });
            setLastConflictCheckTimestamp(Infinity);    // don't keep checking
          }

          // Update live connection info
          if (knownConnections) {     // knownConnections is a list of { displayName, cursorColor, publicConnectionId }
            if (!inLiveMode && knownConnections.length > Object.keys(liveConnectionInfo || {}).length) {
              postNotification({
                variant: 'angry',
                icon: <MdForkRight />,
                headline: 'Enter live mode!',
                content: <span>A new collaborator just entered live mode. You should also <a href={`/construct/${puzzleId}`} onClick={e => {
                  e.preventDefault();
                  enterLiveMode();
                }}>join live mode</a> in order to collaborate real-time and avoid overwriting each other's work.</span>,
                labels: ['request-live-mode', 'puzzle-specific'],
              });
            }

            setLiveConnectionInfo(oldLiveConnectionInfo => {
              // We want to merge the newest connection info with the last known cursor locations from each collaborator
              const newLiveConnectionInfo = Object.fromEntries(knownConnections.map(({ displayName, cursorColor, publicConnectionId }) => {
                const cursorLoc = (oldLiveConnectionInfo && oldLiveConnectionInfo[publicConnectionId]) ? (oldLiveConnectionInfo[publicConnectionId].cursorLoc || null) : null;
                return [publicConnectionId, { displayName, cursorColor, cursorLoc }];
              }));
              return newLiveConnectionInfo;
            });
          }
        } catch (e) {
          console.log('Experiencing some errors connecting to the server. May be offline.');
        }
        
      }, 1000);

      setLastConflictCheckTimestamp(Date.now());    // this is just to throttle to about 60 seconds per API call
    }
  }


  /* Live (collab) mode functions */

  /**
   * Receives an update from the server during live mode construction, and updates the grid/puzzle/furnishings/metadata accordingly.
   * The update consists of the server's latest known puzzle content, along with the user's most recent changeEvent ID that the server knows about.
   * Of course, there might also be some local changeEvents that the server doesn't know about yet; this function also handles merging those in.
   * @param {{puzzleContent, serversLastChangeEventId, knownConnections}} param0 
   * @returns {{ unincorporatedChangeEvents }}
   */
  function handleReceivedUpdate({ puzzleContent, serversLastChangeEventId, knownConnections }) {
    const receivedPuzzleData = fromPuzzleContentObject(puzzleContent);

    // Flatten from a list of changeEventGroups to a list of constituent changeEvents, but only where it's not incorporated yet
    const unincorporatedChangeEvents = changeEventHistoryRef.current.reduce((acc, changeEventGroup) => {
      // Skip all the ones we know for sure have been incorporated already
      if (changeEventGroup.isSaved) return acc;

      // Mark the change event groups that have just now been fully incorporated
      if (changeEventGroup.changeEventList.every(changeEvent => changeEvent.changeEventId <= serversLastChangeEventId)) {
        changeEventGroup.isSaved = true;
      }
      
      // Add in all the local changeEvents that haven't been incorporated by the server yet
      return acc.concat(changeEventGroup.changeEventList.filter(changeEvent => changeEvent.changeEventId > serversLastChangeEventId));
    }, []);

    // Update saved status based on whether there are any "unsaved" changes client-side
    if (unincorporatedChangeEvents.length === 0) {
      setIsSaved(true);
      setCurrentStatusMessage('All changes saved!');
    } else {
      setIsSaved(false);
      setCurrentStatusMessage('Awaiting confirmation...');
    }

    // Merge with change events
    const {
      charGrid: newCharGrid,
      furnishings: newFurnishings,
      clues: newClues,
      puzzleMetadata: newPuzzleMetadata
    } = mergePuzzleWithChangeEvents(receivedPuzzleData, unincorporatedChangeEvents);


    // Update the grid if it's been changed.
    /* 
    We need to use callback-style set state to access most recent values inside asynchronous handler,
    but we also need to preserve certain values calculated from each of the set-state blocks. 
    I tried nesting the various setState blocks, but that ends up running blocks unpredictable numbers of times;
    this solution, where variables are declared outside the blocks and instantiated using the latest values from within the blocks, seems to work.
    */
    var charGridDifferences;
    setCharGrid(oldCharGrid => {
      charGridDifferences = getCharGridDifferences(oldCharGrid, newCharGrid);
      return newCharGrid;
    });

    if (charGridDifferences === null || charGridDifferences.length !== 0) {
      // The grid has been changed; also update slotStructure, slotFillOptions, boardStyle, and ghostChars

      // Define some convenience bools
      const gridHasResized = charGridDifferences === null;
      const blackoutsHaveChanged = gridHasResized || charGridDifferences.some(element => element.char1 === 'blackout' || element.char2 === 'blackout');

      // Update slot structure
      var newSlotStructure;
      var oldSlotStructure_preserved;
      setSlotStructure(oldSlotStructure => {
        oldSlotStructure_preserved = oldSlotStructure;
        newSlotStructure = blackoutsHaveChanged ? 
          SlotStructure.buildNewSlotStructure(newCharGrid) : 
          oldSlotStructure.withReplacedChars(charGridDifferences);
        return newSlotStructure;
      }, false);

      // Update slot fill options
      var newSlotFillOptions;
      setSlotFillOptions(oldSlotFillOptions => {
        newSlotFillOptions = gridHasResized ?
          getSlotFillOptions(newSlotStructure) : blackoutsHaveChanged ?
          getSlotFillOptions(newSlotStructure, oldSlotFillOptions, oldSlotStructure_preserved) :
          modifiedSlotFillOptions(oldSlotFillOptions, newSlotStructure, charGridDifferences);
        return newSlotFillOptions;   // set slotFillOptions state variable
      }, false);

      // Reset cursor if the grid has changed size
      const newCursorLoc = gridHasResized ? null : cursorLoc;
      if (gridHasResized) setCursorLoc(newCursorLoc);

      // Update board style
      setBoardStyle(getBoardStyleFromSlotStructure(newSlotStructure, newSlotFillOptions, puzzlePreferences), false);

      // Try continuous autofill
      tryContinuousAutofill(newSlotStructure);

      // Update word suggestor cache
      updateCharGridForWordSuggestor(newCharGrid, newSlotStructure, newSlotFillOptions);
    }
    
    // Update furnishings
    setFurnishings(newFurnishings, false);

    // Update clues
    setClues(newClues, false);

    // Update metadata if it's been changed
    setPuzzleMetadata(newPuzzleMetadata, false);

    // Finally, update the live connections info
    if (knownConnections) {     // knownConnections is a list of { displayName, cursorColor, publicConnectionId }
      setLiveConnectionInfo(oldLiveConnectionInfo => {
        // We want to merge the newest connection info with the last known cursor locations from each collaborator
        const newLiveConnectionInfo = Object.fromEntries(knownConnections.map(({ displayName, cursorColor, publicConnectionId }) => {
          const cursorLoc = (oldLiveConnectionInfo && oldLiveConnectionInfo[publicConnectionId]) ? (oldLiveConnectionInfo[publicConnectionId].cursorLoc || null) : null;
          return [publicConnectionId, { displayName, cursorColor, cursorLoc }];
        }));
        return newLiveConnectionInfo;
      });
    } else {
      setLiveConnectionInfo({});
    }

    setRenderTrigger(rt => rt + 1);

    return { unincorporatedChangeEvents };
  }

  /**
   * Updates front-end liveConnectionInfo about other collaborators
   * @param {String} publicConnectionId 
   * @param {[r,c] or null} cursorLoc 
   */
  function handleCursorUpdate(publicConnectionId, cursorLoc) {
    setLiveConnectionInfo(oldLiveConnectionInfo => {
      if (oldLiveConnectionInfo && oldLiveConnectionInfo[publicConnectionId]) {
        // Set liveConnectionInfo state
        return produce(oldLiveConnectionInfo, draft => {
          draft[publicConnectionId].cursorLoc = cursorLoc;
        });
      }
    });
  }

  function enterLiveMode(options = {}) {
    if (liveModeIsConnecting) return;  // don't allow spamming connection requests
    const { displayName } = options;

    setLiveModeIsConnecting(true);
    setServerSideModifiedAt(null);  // won't be checking for conflicts during live mode
    removeNotificationsWithLabels(['request-live-mode']);    // don't wait for connection success, as it appears unresponsive to users. See https://www.notion.so/Toast-System-235a221e3b6e4953879e9bf545b3855b?pvs=4

    const wsCallbacks = {
      onConnect: async (receivedData) => {
        setInLiveMode(true);
        setLiveModeIsTimedOut(false);
        setLiveModeIsConnecting(false);

        // Update the board state with the most recent received data (merging with any new local change events)
        const { unincorporatedChangeEvents } = handleReceivedUpdate(receivedData);

        // If there are any new local change events from prior to entering into live mode, submit these to the server now
        if (unincorporatedChangeEvents.length > 0)
        await submitChangeEventsToServer(unincorporatedChangeEvents);
      },
      handleReceivedUpdate,
      handleCursorUpdate,
      onError: (props = {}) => {
        const { cause = 'error' } = props;
        setInLiveMode(false);
        setLiveModeIsConnecting(false);
        setLiveConnectionInfo({});

        // Post an error notification to the user
        if (cause === 'timeout') {
          setLiveModeIsTimedOut(true);
        } else if (cause === 'no ticket') {
          postErrorNotification(
            'No connection',
            'We couldn\'t establish a connection with the server. Check your internet connection.',
            ['puzzle-specific']
          );
          setLiveModeIsTimedOut(false);
        } else {   // cause should be 'error'
          postErrorNotification(
            'Live mode error',
            'Sorry! We\'re experiencing errors with the live connection right now. Make sure you have "edit" or "manage" permissions, or check your internet connection.',
            ['puzzle-specific']
          );
          setLiveModeIsTimedOut(false);
        }
      }
    };

    try {
      openNewWsConnection(puzzleId, wsCallbacks, { displayName });
      // All the other state values are set in the onConnect callback!
    } catch (e) {
      closeWsConnection();
      console.log(e);
      postErrorNotification('Oops!', 'Having a little trouble with live connections right now.', ['puzzle-specific']);
      setInLiveMode(false);
      setLiveModeIsTimedOut(false);
      setLiveModeIsConnecting(false);
      setLiveConnectionInfo({});
    }
  }

  function exitLiveMode() {
    closeWsConnection();
    setInLiveMode(false);
    setLiveConnectionInfo({});
  }

  // We need a cleanup function before this component unmounts, in case the live connection is still open (e.g. user clicks on Crossworthy Home during a live session)
  useEffect(() => {
    return () => {
      closeWsConnection();
    }
  }, []);



  /**
   * Returns the correct BoardStyle object describing all the appropriate formatting for the cells,
   * based on slots <2 cells long, repeated words, slots with no fill suggestions, etc.
   * (Does not call setBoardStyle.)
   * @param {SlotStructure} newSlotStructure
   * @param {Map} newSlotFillOptions
   * @param {[[r, c]]} stagedLocs
   * @param {Map} puzzlePreferences Define the cell formatting for the various warnings etc.
   */
  function getBoardStyleFromSlotStructure(newSlotStructure, newSlotFillOptions, puzzlePreferences) {

    var cellStyleNameList = [];  // running list of {loc, cellStyleName}

    // Priority 1: check for full slots with repeated words (error)
    const duplicateWords = newSlotStructure.getAllCompleteWords().filter((val, idx, arr) => arr.indexOf(val) !== idx);
    cellStyleNameList = cellStyleNameList.concat(
      Array.from(newSlotStructure.slotStructureMap.values())
          .filter(slot => slot.chars.every(char => char !== '') && duplicateWords.includes(slot.chars.join('')))
          .reduce((locList, slot) => locList.concat(slot.locs), [])
          .filter(newFormattedLoc => cellStyleNameList.every(({loc}) => !locsEqual(newFormattedLoc, loc)))
          .map(loc => { return { loc: loc, cellStyleName: puzzlePreferences.get(PuzzlePreferenceKey.WARNING_STYLE_DUPLICATE_WORDS) } })
    );

    // Priority 2: check for slots < 3 cells long
    cellStyleNameList = cellStyleNameList.concat(
      newSlotStructure.getSlotsWithLength(1).concat(newSlotStructure.getSlotsWithLength(2))
          .reduce((locList, slot) => locList.concat(slot.locs), [])
          .filter(newFormattedLoc => cellStyleNameList.every(({loc}) => !locsEqual(newFormattedLoc, loc)))
          .map(loc => { return { loc: loc, cellStyleName: puzzlePreferences.get(PuzzlePreferenceKey.WARNING_STYLE_SHORT_WORDS) } })
    );

    // Priority 3: check for empty cells in slots without any word suggestions (warning) - only if word database is loaded
    if (newSlotFillOptions !== null) {
      cellStyleNameList = cellStyleNameList.concat(
        newSlotStructure.getLocsInSlots(
          Array.from(newSlotFillOptions.entries())
              .filter(([slotName, fillOptions]) => fillOptions && fillOptions.length === 0 && newSlotStructure.getCharsInSlot(slotName).some(ch => ch === ''))
              .map(([slotName, fillOptions]) => slotName)
        )
            .filter(newFormattedLoc => cellStyleNameList.every(({loc}) => !locsEqual(newFormattedLoc, loc)))
            .map(loc => { return { loc: loc, cellStyleName: puzzlePreferences.get(PuzzlePreferenceKey.WARNING_STYLE_NO_SUGGESTIONS) } })
      );
    }

    // Finally, if any of these warning styles have been set to the "default" style, just remove them from the list (so that it doesn't overwrite user-defined furnishings, for instance)
    cellStyleNameList = cellStyleNameList.filter(({ cellStyleName }) => cellStyleName !== CellStyleName.DEFAULT);

    return new BoardStyle(cellStyleNameList);
  }


  /**
   * Appends the given ChangeEventGroup as a grouping of simultaneous changes to the history queue.
   * Also handles live mode, i.e. checks for activity and submits its component change events to the server.
   * @param {ChangeEventGroup} changeEventGroup
   */
  async function submitChangeEvents(changeEventGroup) {
    changeEventHistoryRef.current.push(changeEventGroup);

    if (inLiveMode) {
      await submitChangeEventsToServer(changeEventGroup.changeEventList);
    } else if (liveModeIsTimedOut) {
      // Reenter live mode
      enterLiveMode()
    }
  }



  /**
   * The preferred method to set the charGrid state when user specifically is editing the charGrid manually (rather than through loading, undoing, etc.).
   * Sets the charGrid, and then also handles the checks and actions relevant to users editing the charGrid, that is:
   * (1) Keeping slotStructure, slotFillOptions, and boardStyle in sync with the new charGrid (with native setCharGrid, they must be set at point of use)
   * (2) Attempting continuous autofill, and updating word suggestor cache
   * (3) Erasing clue text for slots whose grid content has changed, and erasing furnishings/clues if grid size changes
   * (4) Generating & submitting change events for the grid, clue, and furnishing changes; if it's an excessive number, condense change events into one overhaul change event
   * (5) Setting isSaved to false
   * Note that this function ONLY executes if the newCharGrid has different characters in the cells than the old charGrid.
   * @param {[[string]]} newCharGrid 
   * @param {[ChangeEvent]} simultaneousChangeEventsToExecute Optional list of change events to add to the ChangeEventGroup containing the charGrid changes
   */
  function setCharGridAndHandleAffairs(newCharGrid, simultaneousChangeEventsToExecute = []) {

    // Check if the grids are actually different -- in some cases (e.g. clears grid twice in a row), it may not be
    const charGridDifferences = getCharGridDifferences(charGrid, newCharGrid);
    if (charGridDifferences !== null && charGridDifferences.length === 0) {
      return;   // no changes to charGrid
    }

    // Define some convenience bools
    const gridHasResized = charGridDifferences === null;
    const blackoutsHaveChanged = gridHasResized || charGridDifferences.some(element => element.char1 === 'blackout' || element.char2 === 'blackout');

    // Set charGrid
    const oldCharGrid = charGrid;
    setCharGrid(newCharGrid, false);

    // Update slotStructure to sync with the charGrids
    const oldSlotStructure = slotStructure;
    const newSlotStructure = blackoutsHaveChanged ? 
      SlotStructure.buildNewSlotStructure(newCharGrid) : 
      oldSlotStructure.withReplacedChars(charGridDifferences);
    setSlotStructure(newSlotStructure, false);

    // Update slotFillOptions, assuming the word database has loaded
    let newSlotFillOptions = slotFillOptions;
    if (slotFillOptions !== null) {   // ensure word database has loaded
      newSlotFillOptions = gridHasResized ?
        getSlotFillOptions(newSlotStructure) : blackoutsHaveChanged ?
        getSlotFillOptions(newSlotStructure, slotFillOptions, oldSlotStructure) :
        modifiedSlotFillOptions(slotFillOptions, newSlotStructure, charGridDifferences);
      setSlotFillOptions(newSlotFillOptions, false);
    }

    // Update boardStyle to sync with charGrid
    setBoardStyle(getBoardStyleFromSlotStructure(newSlotStructure, newSlotFillOptions, puzzlePreferences), false);

    // Attempt continuous autofill
    tryContinuousAutofill(newSlotStructure, undefined, undefined, false);

    // Update word suggestor feature
    updateCharGridForWordSuggestor(newCharGrid, newSlotStructure, newSlotFillOptions);

    // Complete knock-on effects to the puzzle data due to these charGrid changes; i.e.
    //  - remove any invisibility furnishings from deleted blackouts
    //  - remove any existing clues from affected slots
    // We'll also build a list of changeEvents to submit all these knock-ons as one batch.
    var changeEvents = [...simultaneousChangeEventsToExecute];

    // First remember which clues should be preserved (nonempty clues with unchanged slots). 
    const preservedClues = [];     // preserve in a list of objects {loc: [r, c], direction, clueText: ..}

    if (gridHasResized) {    // grid re-sized
      // Don't preserve any clues (do nothing); and also remove all furnishings
      changeEvents.push(...changeFurnishings(furnishingsObjectToList(furnishings), null, true));

    } else if (blackoutsHaveChanged) {   // blackouts changed (requires clue renumbering), but grid is the same size

      // Now tackle the clue renumbering
      oldSlotStructure.slotStructureMap.forEach((oldSlot, oldSlotName) => {
        // For each old slot, preserve the clue text iff it wasn't empty string and the old slot had the same starting loc and array of chars as a new slot
        if (clues.get(oldSlotName)) {   // is this clue worth saving (i.e. does it contain any text)?
          const oldStartingLoc = oldSlot.locs[0];
          const newSlotName = newSlotStructure.getSlotNameContainingCoordinates(oldStartingLoc, oldSlot.direction);
          if (newSlotName) {                       // does a new slot exist at this location?
            const newStartingLoc = newSlotStructure.getLocFromSlotName(newSlotName);
            if (newStartingLoc[0] === oldStartingLoc[0] && newStartingLoc[1] === oldStartingLoc[1]) {   // do the starting locs match?
              const oldChars = oldSlotStructure.getCharsInSlot(oldSlotName);
              const newChars = newSlotStructure.getCharsInSlot(newSlotName);
              if (oldChars.length === newChars.length && oldChars.every((val, idx) => val === newChars[idx])) {  // do the chars arrays match, incl. empty spaces?
                // Must preserve!
                preservedClues.push({ loc: oldStartingLoc, direction: oldSlot.direction, clueText: clues.get(oldSlotName) });
              }
            }
          }
        }
      });

    } else {        // no re-numbering required, but some clues may not have valid words anymore
      
      // Iterate through the slotStructure to see which arrays of chars are the same (the slotNames and locs should be the same between new and old slotStructures)
      oldSlotStructure.slotStructureMap.forEach((oldSlot, oldSlotName) => {
        if (clues.get(oldSlotName)) { // is this clue worth saving or changing (i.e. does it contain any text)?
          const oldChars = oldSlotStructure.getCharsInSlot(oldSlotName);
          const newChars = newSlotStructure.getCharsInSlot(oldSlotName);
          if (oldChars.every((val, idx) => val === newChars[idx])) {  // do the chars arrays match, incl. empty spaces?
            // Must preserve!
            preservedClues.push({ loc: oldSlotStructure.getLocFromSlotName(oldSlotName), direction: oldSlot.direction, clueText: clues.get(oldSlotName) });
          } else {
            // If we're not preserving the clue, then it's being changed to an empty clue, so we'll need to record a change event
            changeEvents.push(newClueChangeEvent(oldSlotName, getSlotIndexFromSlotName(oldSlotName, clues), clues.get(oldSlotName), '', [numRows, numCols]));
          }
        }
      });

    }

    // Renumber and reset clues to empty strings, then reassign the preserved clues
    const newClues = new Map(newSlotStructure.slotNames.map(sName => [sName, '']));
    for (let i = 0; i < preservedClues.length; ++i) {
      const {loc, direction, clueText} = preservedClues[i];
      const newSlotName = newSlotStructure.getSlotNameContainingCoordinates(loc, direction);
      newClues.set(newSlotName, clueText);
    }
    const oldClues = clues;
    setClues(newClues, false);

    
    // Generate change events
    if (gridHasResized) {
      changeEvents.push(newOverhaulChangeEvent(oldCharGrid, emptyFurnishingsObject(), oldClues, puzzleMetadata, newCharGrid, [], newClues, puzzleMetadata));
    } else {
      // Iterate through grid to determine changes
      for (let r = 0; r < numRows; ++r) {
        for (let c = 0; c < numCols; ++c) {
          if (oldCharGrid[r][c] !== newCharGrid[r][c]) {
            changeEvents.push(newGridChangeEvent([r,c], oldCharGrid[r][c], newCharGrid[r][c], [numRows, numCols]));
          }
        }
      }
      // If there was a blackout added/removed, then the clues would've been renumbered
      if (blackoutsHaveChanged) {
        changeEvents.push(newClueOverhaulChangeEvent(oldClues, newClues, [numRows, numCols]));
      }
    }

    // Submit change events. If there were more than, say, 45, we're reasonably sure they can be treated as an overhaul event
    if (changeEvents.length > 45) {
      changeEvents = [newOverhaulChangeEvent(oldCharGrid, furnishings, oldClues, puzzleMetadata, newCharGrid, furnishings, newClues, puzzleMetadata)];
    }
    submitChangeEvents(new ChangeEventGroup({ changeEventList: changeEvents }));

    // Note that the updated version is no longer saved
    setIsSaved(false, false);
    resetCurrentStatusMessage();

    setRenderTrigger(rt => rt + 1);
  }



  /**
   * Updates the charGrid at the given locs with the given new values. Will maintain symmetry with blackouts depending on puzzle preferences.
   * @param {[[number, number]]} locs List of [r, c] coordinates at which to insert new characters/blackouts
   * @param {[string] || string} newChars A single value to insert at every loc (e.g. 'blackout'), or a list of equal length to locs
   * @param {[ChangeEvent]} simultaneousChangeEventsToExecute Optional list of change events to add to the ChangeEventGroup containing the charGrid changes
   */
  function updateCharGridAtLocs(locs, newChars, simultaneousChangeEventsToExecute=[]) {
    if (puzzleInteractionStatus === PuzzleInteractionStatus.STATUESQUE || puzzleInteractionStatus === PuzzleInteractionStatus.THINKING) return;

    if (typeof(newChars) !== 'string' && locs.length !== newChars.length) {
      console.warn('BoardInteractionContext.js updateCharGridAtLocs: newChars must be a string or a list of equal length to locs; doing nothing');
      return;
    }

    if (typeof(newChars) === 'string')
      newChars = new Array(locs.length).fill(newChars);

    if (newChars.some(c => !(c === '' || c === 'blackout' || /^[A-Z]$/.test(c)))) {
      console.warn('updateCharGridAtLocs was called where some of the newChars are not acceptable characters. Doing nothing');
      postErrorNotification(
        'Oops!', 
        `We weren't able to insert the characters "${newChars.join(', ')}" because one of them isn't supported yet. ` +
        'Hopefully we\'ll get around to supporting this soon!',
        ['puzzle-specific']
      );
      return;
    }

    // Get the updated grid
    const newCharGrid = produce(charGrid, (draft) => {
      for (let i = 0; i < locs.length; ++i) {
        const [r, c] = locs[i];
        const insertion = newChars[i];
        if (puzzleMetadata.get(PuzzleMetadataKey.SYMMETRY_TYPE) === SymmetryType.ROTATIONAL) {
          const [symmR, symmC] = getRotationallySymmetricLoc(locs[i], numRows, numCols);
          if (draft[r][c] === 'blackout' && draft[symmR][symmC] === 'blackout') {
            draft[symmR][symmC] = '';  // if deleting a blackout, delete its symmetrical counterpart too
          }
          if (insertion === 'blackout') {
            draft[symmR][symmC] = 'blackout';   // if inserting a blackout, insert its symmetrical counterpart too
          }
        }
        draft[r][c] = insertion;
      }
    });

    // Actually update the grid
    setCharGridAndHandleAffairs(newCharGrid, simultaneousChangeEventsToExecute);
  }


  /** 
   * Clears the charGrid and sets it to the new dimensions specified.
   * Also clears furnishings.
   * @param {number} newNumRows 
   * @param {number} newNumCols
   */
  function changeCharGridDimensions(newNumRows, newNumCols) {
    if (puzzleInteractionStatus === PuzzleInteractionStatus.STATUESQUE || puzzleInteractionStatus === PuzzleInteractionStatus.THINKING) return;

    setFurnishings(emptyFurnishingsObject());

    const newCharGrid = emptyCharGrid(newNumRows, newNumCols);
    setCursorLoc(null);   // otherwise will get an index out of bounds error
    setCharGridAndHandleAffairs(newCharGrid);
  }



  // Modifying furnishing values

  /** Modifies the fundamental furnishings state value; filters out duplicates, and submits a change event group */
  /**
   * Modifies the "fundamental" furnishings state value (removes and/or adds any number of old/new furnishings). Also creates and submits associated change events.
   * Removes furnishings first (*not* requiring equal values, i.e. if the color furnishing is updated, it will first remove any existing color furnishing
   * at that loc - this is to allow removal of furnishings without knowing their old value).
   * Then adds new furnishings (overwriting existing furnishings for those types/locations).
   * Also sets isSaved to false.
   * @param {[Furnishing]?} furnishingsToRemove 
   * @param {[Furnishing]?} furnishingsToAdd 
   * @param {boolean} suppressChangeEventSubmission if given as true, will NOT submit the resultant changeEvents (will still return those change events)
   * @returns {[ChangeEvent]} a list of changeEvents that was submitted (or not submitted, if suppressChangeEventSubmission is true)
   */
  function changeFurnishings(furnishingsToRemove, furnishingsToAdd, suppressChangeEventSubmission = false) {
    if (!furnishingsToRemove) furnishingsToRemove = [];
    if (!furnishingsToAdd) furnishingsToAdd = [];

    const furnishingChangeEvents = [];  // keep track of this separately since furnishingsToRemove may contain duplicates; i.e. this is a subset of that
    
    // First remove any furnishings; it's important to restrict furnishingsToRemove to those actually in the list (otherwise undo functionality is buggy)
    var changedFurnishings = JSON.parse(JSON.stringify(furnishings));
    furnishingsToRemove.forEach(f => {
      if (changedFurnishings[f.furnishingType]?.[JSON.stringify(f.loc)]) {
        furnishingChangeEvents.push(newFurnishingChangeEvent(f.furnishingType, f.loc, changedFurnishings[f.furnishingType]?.[JSON.stringify(f.loc)], undefined, [numRows, numCols]));
        delete changedFurnishings[f.furnishingType]?.[JSON.stringify(f.loc)];
      }
    });

    // Next add in non-duplicate new furnishings and set it to the new state value
    furnishingsToAdd.forEach(f => {
      // Ensure it's not an exact duplicate
      if (changedFurnishings[f.furnishingType]?.[JSON.stringify(f.loc)] !== f.value) {
        furnishingChangeEvents.push(newFurnishingChangeEvent(f.furnishingType, f.loc, changedFurnishings[f.furnishingType]?.[JSON.stringify(f.loc)], f.value, [numRows, numCols]));
        if (changedFurnishings[f.furnishingType]) {
          changedFurnishings[f.furnishingType][JSON.stringify(f.loc)] = f.value;
        } else {
          changedFurnishings[f.furnishingType] = Object.fromEntries([[JSON.stringify(f.loc), f.value]]);
        }
      }
    });

    setFurnishings(changedFurnishings);

    // Submit a change event group
    if (!suppressChangeEventSubmission) submitChangeEvents(new ChangeEventGroup({ changeEventList: furnishingChangeEvents }));

    setIsSaved(false);

    return furnishingChangeEvents;
  }

  /**
   * Removes ANY furnishing in any of the given locs. (Wrapper around changeFurnishings)
   * @param {*} listOfLocs 
   * @param {boolean} suppressChangeEventSubmission if given as true, will NOT submit the resultant changeEvents (will still return those change events)
   * @returns {[ChangeEvent]} a list of changeEvents that was submitted (or not submitted, if suppressChangeEventSubmission is true)
   */
  function removeFurnishingsAtLocs(listOfLocs, suppressChangeEventSubmission = false) {
    return changeFurnishings(furnishingsObjectToList(furnishings).filter(f => locInList(f.loc, listOfLocs)), null, suppressChangeEventSubmission);
  }


  /**
   * @param {[r,c]} loc 
   * @param {FurnishingType} furnishingType 
   * @returns the value of the furnishing at the given location & given type, otherwise undefined
   */
  function furnishingValueAt(loc, furnishingType) {
    return furnishings[furnishingType]?.[JSON.stringify(loc)];
  }


  /**
   * Adds a color furnishing to the specified cell(s) (and replaces any old color furnishing for the specified locs).
   * @param {string} color A color hex code
   * @param {*} locs Either a list of locs or a single loc to add this color to as a furnishing
   */
  function updateColorFurnishing(hexColor, locs) {
    if (!locs.length) return;   // returns if given empty list
    if (!Array.isArray(locs[0])) {  // convert to a list of locs if given a single loc
      locs = [locs];
    }

    const newFurnishings = locs.map(loc => newColorFurnishing(loc, hexColor));
    changeFurnishings(null, newFurnishings);  // this will first remove any existing color furnishings in the specified locs
  }

  /**
   * Removes any color furnishings from the specified cell(s).
   * @param {[r,c] | [[r,c]]} locs Either a list of locs or a single loc to remove any color furnishings from
   */
  function removeColorFurnishing(locs) {
    if (!locs.length) return;
    if (!Array.isArray(locs[0])) {
      locs = [locs];
    }

    const furnishingsToRemove = locs.map(loc => newColorFurnishing(loc, '#000000'));
    changeFurnishings(furnishingsToRemove, null);
  }


  /**
   * Turns all the circle furnishings on or off in the given loc(s).
   * @param {[r,c] | [[r,c]]} locs 
   * @param {*} on whether we want all the locs to have circles (true) or not have circles (false)
   */
  function toggleCircleFurnishingsAtLocs(locs, on = true) {
    if (!locs.length) return;
    if (!Array.isArray(locs[0])) {
      locs = [locs];
    }

    if (on) {
      changeFurnishings(null, locs.map(loc => newCircleFurnishing(loc)));
    } else {
      changeFurnishings(locs.map(loc => newCircleFurnishing(loc)), null);
    }
  }


  function makeOuterBlackoutsInvisible() {
    changeFurnishings(null, getAllOuterBlackouts(charGrid).map(loc => newInvisibilityFurnishing(loc)));
  }

  function removeAllInvisibilityFurnishings() {
    changeFurnishings(furnishingsObjectToList(furnishings).filter(f => f.furnishingType === FurnishingType.INVISIBILITY));
  }


  /**
   * Sets the clue text for the given slot names (e.g. "1-down").
   * Also submits the appropriate changeEvents.
   * @param {[{slotName, clueText}]} slotsAndClues List of objects containing slotName and clueText properties
   */
  function setCluesAt(slotsAndClues) {
    if (puzzleInteractionStatus === PuzzleInteractionStatus.STATUESQUE || puzzleInteractionStatus === PuzzleInteractionStatus.THINKING) return;

    const newClues = produce(clues, (draft) => {
      for (let obj of slotsAndClues) {
        draft.set(obj.slotName, obj.clueText);
      }
    });

    submitChangeEvents(new ChangeEventGroup({
      changeEventList: slotsAndClues.map(({slotName, clueText}) => {
        const slotIndex = getSlotIndexFromSlotName(slotName, newClues);
        return newClueChangeEvent(slotName, slotIndex, clues.get(slotName), clueText, [numRows, numCols])
      }),
    }));

    setClues(newClues);
    setIsSaved(false);
    resetCurrentStatusMessage();
  }


  /**
   * Returns the puzzle preference of the given PuzzlePreferenceKey.
   * @param {PuzzlePreferenceKey} puzzlePreferenceKey 
   * @returns {*}
   */
  function getPuzzlePreference(puzzlePreferenceKey) {
    return puzzlePreferences.get(puzzlePreferenceKey);
  }

  /**
   * Sets the puzzle preference of the given PuzzlePreferenceKey.
   * Note that calling this repeatedly within the same render cycle will only keep one of the requested changes.
   * @param {PuzzlePreferenceKey} puzzlePreferenceKey 
   * @param {*} newPuzzlePreference 
   */
  function setPuzzlePreference(puzzlePreferenceKey, newPuzzlePreference) {
    const newPuzzlePreferences = produce(puzzlePreferences, (draft) => {
      draft.set(puzzlePreferenceKey, newPuzzlePreference);
    });
    
    setPuzzlePreferences(newPuzzlePreferences);

    // Re-render in case this would have changed the styling
    setBoardStyle(getBoardStyleFromSlotStructure(slotStructure, slotFillOptions, newPuzzlePreferences));
  }


  // Generating new blackouts
  function designBlackouts() {
    return new Promise((resolve, reject) => {
      resolve(/*useHumanSelectedPatterns*/ false ? pickHumanBlackouts(charGrid.length, charGrid[0].length) : generateBlackouts(charGrid.length, charGrid[0].length));
        // TODO: human selected patterns
    });
  }
  async function requestNewBlackouts(onSuccess = null, onFailure = null) {
    if (puzzleInteractionStatus === PuzzleInteractionStatus.STATUESQUE || puzzleInteractionStatus === PuzzleInteractionStatus.THINKING) return;
      if (furnishings[FurnishingType.INVISIBILITY] && Object.keys(furnishings[FurnishingType.INVISIBILITY]).length > 0) // delete any invisibility furnishings
        changeFurnishings(furnishingsObjectToList(furnishings).filter(f => f.furnishingType === FurnishingType.INVISIBILITY), null);

    try {
      let newBlackouts = await designBlackouts();

      let locsToChange = [];
      let newChars = [];
      // Clear existing blackouts
      let oldBlackouts = [];
      for (let r = 0; r < charGrid.length; ++r) {
        for (let c = 0; c < charGrid[0].length; ++c) {
          if (charGrid[r][c] === 'blackout') {
            locsToChange.push([r, c]);
            newChars.push('');
            oldBlackouts.push([r, c]);
          }
        }
      }
      if (puzzlePreferences.get(PuzzlePreferenceKey.BLACKOUTS_PRESERVE_WORDS)) {
        // If we're preserving the words, then add blackouts bookending any group of 3+ consecutive filled cells
        for (let r = 0; r < charGrid.length; ++r) {   // rowwise first
          let numConsecutive = 0;
          for (let c = 0; c < charGrid[0].length; ++c) {
            if (charGrid[r][c] !== '' && charGrid[r][c] !== 'blackout') {
              numConsecutive += 1;
              newBlackouts = removeLoc([r, c], removeLoc(getRotationallySymmetricLoc([r, c], charGrid.length, charGrid[0].length), newBlackouts));
            } else {
              if (numConsecutive >= 3) {
                // Tail-end blackout for the word
                locsToChange.push([r, c]);
                newChars.push('blackout');
                if (c >= charGrid.length - 3) {
                  // Fill in the squares here too, since they're too close to the edge to be words anyway
                  for (let i = 1; c+i < charGrid.length; ++i) {
                    if (charGrid[r][c+i] === '' || charGrid[r][c+i] === 'blackout') {
                      locsToChange.push([r, c+i]);
                      newChars.push('blackout');
                    }
                  }
                }

                // Head-end blackout for the word
                if (c - numConsecutive - 1 >= 0) {
                  locsToChange.push([r, c-numConsecutive-1]);
                  newChars.push('blackout');
                  if (c-numConsecutive-1 <= 2) {
                    // Fill in the squares here too, since they're too close to the edge to be words anyway
                    for (let i = 1; c-numConsecutive-1-i >= 0; ++i) {
                      if (charGrid[r][c-numConsecutive-1-i] === '' || charGrid[r][c-numConsecutive-1-i] === 'blackout') {
                        locsToChange.push([r, c-numConsecutive-1-i]);
                        newChars.push('blackout');
                      }
                    }
                  }
                }
              }
              numConsecutive = 0;
            }
          }
          if (numConsecutive >= 3 && numConsecutive < charGrid[0].length) { // do the same thing at the end of the row
            locsToChange.push([r, charGrid[0].length-numConsecutive-1]);
            newChars.push('blackout');
          }
        }
        for (let c = 0; c < charGrid[0].length; ++c) {   // columnwise now
          let numConsecutive = 0;
          for (let r = 0; r < charGrid.length; ++r) {
            if (charGrid[r][c] !== '' && charGrid[r][c] !== 'blackout') {
              numConsecutive += 1;
              newBlackouts = removeLoc([r, c], newBlackouts);
            } else {
              if (numConsecutive >= 3) {
                // Tail-end blackout for the word
                locsToChange.push([r, c]);
                newChars.push('blackout');
                if (r >= charGrid[0].length - 3) {
                  // Fill in the squares here too, since they're too close to the edge to be words anyway
                  for (let i = 1; r+i < charGrid[0].length; ++i) {
                    if (charGrid[r+i][c] === '' || charGrid[r+i][c] === 'blackout') {
                      locsToChange.push([r+i, c]);
                      newChars.push('blackout');
                    }
                  }
                }

                // Head-end blackout for the word
                if (r - numConsecutive - 1 >= 0) {
                  locsToChange.push([r-numConsecutive-1, c]);
                  newChars.push('blackout');
                  if (r-numConsecutive-1 <= 2) {
                    // Fill in the squares here too, since they're too close to the edge to be words anyway
                    for (let i = 1; r-numConsecutive-1-i >= 0; ++i) {
                      if (charGrid[r-numConsecutive-1-i][c] === '' || charGrid[r-numConsecutive-1-i][c] === 'blackout') {
                        locsToChange.push([r-numConsecutive-1-i, c]);
                        newChars.push('blackout');
                      }
                    }
                  }
                }
              }
              numConsecutive = 0;
            }
          }
          if (numConsecutive >= 3 && numConsecutive < charGrid.length) { // do the same thing at the end of the col
            locsToChange.push([charGrid.length-numConsecutive-1, c]);
            newChars.push('blackout');
          }
        }
      }
      // Add new blackouts
      for (let i = 0; i < newBlackouts.length; ++i) {
        locsToChange.push(newBlackouts[i]);
        newChars.push('blackout');
      }

      // Remove any furnishings on any of the locs that were blackouts or are becoming blackouts (not just locsToChange)
      const simultaneousChangeEvents = removeFurnishingsAtLocs(newBlackouts.concat(oldBlackouts), true);   
      // Update the charGrid
      updateCharGridAtLocs(locsToChange, newChars, simultaneousChangeEvents);  // won't update symmetrical locs as well, since generateBlackouts should control that

      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      postErrorNotification(
        'Oops!',
        'There was an error generating blackouts. Try again or let us know.',
        ['puzzle-specific']
      );
      console.log(e);

      if (onFailure) {
        onFailure();
      }
    }
  }



  /**
   * Solidifies all ghostChars into actual changes in the charGrid.
   */
  function acceptGhostChars() {
    if (puzzleInteractionStatus === PuzzleInteractionStatus.STATUESQUE || puzzleInteractionStatus === PuzzleInteractionStatus.THINKING) return;
    
    updateCharGridAtLocs(ghostChars.map(({ loc }) => loc), ghostChars.map(({ char }) => char));
    // The above function will also reset the ghostChars, so no need to do so explicitly here
  }


  /**
   * Shortcut to turn off all warnings through puzzle preferences.
   */
  function turnOffWarnings() {
    const newPuzzlePreferences = produce(puzzlePreferences, (draft) => {
      draft.set(PuzzlePreferenceKey.WARNING_STYLE_SHORT_WORDS, CellStyleName.DEFAULT);
      draft.set(PuzzlePreferenceKey.WARNING_STYLE_DUPLICATE_WORDS, CellStyleName.DEFAULT);
      draft.set(PuzzlePreferenceKey.WARNING_STYLE_NO_SUGGESTIONS, CellStyleName.DEFAULT);
    });
    setPuzzlePreferences(newPuzzlePreferences);
    // Re-render in case this would have changed the styling
    setBoardStyle(getBoardStyleFromSlotStructure(slotStructure, slotFillOptions, newPuzzlePreferences));
  }

  /**
   * Shortcut to turn on warnings through puzzle preferences.
   */
  function turnOnWarnings() {
    const newPuzzlePreferences = produce(puzzlePreferences, (draft) => {
      draft.set(PuzzlePreferenceKey.WARNING_STYLE_SHORT_WORDS, CellStyleName.ERROR);
      draft.set(PuzzlePreferenceKey.WARNING_STYLE_DUPLICATE_WORDS, CellStyleName.ERROR);
      draft.set(PuzzlePreferenceKey.WARNING_STYLE_NO_SUGGESTIONS, CellStyleName.WARNING);
    });
    setPuzzlePreferences(newPuzzlePreferences);
    // Re-render in case this would have changed the styling
    setBoardStyle(getBoardStyleFromSlotStructure(slotStructure, slotFillOptions, newPuzzlePreferences));
  }

  const areAnyWarningsOn = (
    puzzlePreferences.get(PuzzlePreferenceKey.WARNING_STYLE_NO_SUGGESTIONS) !== CellStyleName.DEFAULT ||
    puzzlePreferences.get(PuzzlePreferenceKey.WARNING_STYLE_DUPLICATE_WORDS) !== CellStyleName.DEFAULT ||
    puzzlePreferences.get(PuzzlePreferenceKey.WARNING_STYLE_SHORT_WORDS) !== CellStyleName.DEFAULT
  );


  /**
   * Sets the value for the given key (e.g. TITLE) in puzzle metadata.
   * @param {PuzzleMetadataKey} puzzleMetadataKey 
   * @param {string} newPuzzleMetavalue 
   */
  function setPuzzleMetavalue(puzzleMetadataKey, newPuzzleMetavalue) {
    const newPuzzleMetadata = produce(puzzleMetadata, (draft) => {
      draft.set(puzzleMetadataKey, newPuzzleMetavalue);
    });

    submitChangeEvents(new ChangeEventGroup({
      changeEventList: [newMetadataChangeEvent(puzzleMetadataKey, puzzleMetadata.get(puzzleMetadataKey), newPuzzleMetavalue)]
    }));

    setPuzzleMetadata(newPuzzleMetadata);
    setIsSaved(false);
    resetCurrentStatusMessage();
  }


  function putToDynamoDb() {
    return post({
      apiName: 'userPuzzles',
      path: `/userPuzzles/${puzzleId}`,
      options: {
        body: {
          puzzleContent: toPuzzleContentObject(charGrid, furnishings, clues, puzzleMetadata, slotStructure),
        }
      },
    }).response;
  }
  async function requestPuzzleSave(onSuccess = null, onFailure = null) {
    if (inLiveMode || liveModeIsConnecting) return;  // disable this function if live mode is on or about to be on

    if (isAuthenticated) {
      if (puzzleId && puzzleInteractionStatus === PuzzleInteractionStatus.EDITABLE) {
        setPuzzleInteractionStatus(PuzzleInteractionStatus.THINKING);
        setIsSaving(true);
        setCurrentStatusMessage('Saving...');
        try {
          const { modifiedAt } = await putToDynamoDb();
          for (let changeEventGroup of changeEventHistoryRef.current) {
            changeEventGroup.isSubmitted = true;
          }
          setPuzzleInteractionStatus(baselinePuzzleInteractionStatus);
          setIsSaved(true);
          setIsSaving(false);
          setCurrentStatusMessage('All changes saved.');
          setServerSideModifiedAt(modifiedAt);
          if (onSuccess) {
            onSuccess();
          }
          return;
        } catch (e) {
          console.warn('Error saving puzzle:');
          console.log(e);
          setPuzzleInteractionStatus(baselinePuzzleInteractionStatus);
          setIsSaving(false);
          postErrorNotification('Oops!', 'Sorry, there was an error saving your puzzle. Please try again or email us to let us know.');
        }
      } // no "else" error notification for now, since the most common case would be double-saving in quick succession
    } else {
      postErrorNotification('Oops!', 'In order to save and come back to your puzzles, you need to be signed in.', ['puzzle-specific']);
    }
    if (onFailure) {
      onFailure();
    }
  }


  /**
   * Returns a ChangeEventGroup that would "undo" the last change in the puzzle's changeEventHistoryRef.current.
   * https://www.notion.so/Undo-Redo-4c6bb77444b1463e9fde459b521d121a for background and function logic.
   * @param {number} lastIndex The starting point in changeEventHistoryRef.current from which the function will walk backward recursively.
   * @returns {ChangeEventGroup?} The ChangeEventGroup that should be executed in order to perform the requested undo option, or null if it doesn't exist.
   */
  function undo(lastIndex) {
    if (lastIndex < 0 || lastIndex >= changeEventHistoryRef.current.length) return null;  // no event group to be found

    const lastEventGroup = changeEventHistoryRef.current[lastIndex];
    // TODO: Determine if additional event groups may be included as a major change to undo

    if (!lastEventGroup.isUndo && !lastEventGroup.isRedo) {
      return undoneChangeEventGroups([lastEventGroup]);
    }

    var originalChangeEventGroupIndex;
    if (lastEventGroup.isUndo) {
      // Walk backward until the original ChangeEventGroup is found
      for (
        originalChangeEventGroupIndex = lastIndex; 
        changeEventHistoryRef.current[originalChangeEventGroupIndex].changeEventGroupId !== Math.min(...lastEventGroup.undoneChangeEventGroupIds);
        --originalChangeEventGroupIndex
      );  // upon exit of this loop, originalChangeEventGroupIndex is the minimum index in changeEventHistoryRef.current referencing an undone event
      
      return undo(originalChangeEventGroupIndex - 1);
    }

    if (lastEventGroup.isRedo) { // i.e. else
      // Walk backward until the original ChangeEventGroup is found
      for (
        originalChangeEventGroupIndex = lastIndex; 
        changeEventHistoryRef.current[originalChangeEventGroupIndex].changeEventGroupId !== Math.min(...lastEventGroup.redoneChangeEventGroupIds);
        --originalChangeEventGroupIndex
      );

      return undoneChangeEventGroups([changeEventHistoryRef.current[originalChangeEventGroupIndex]]);
    }
  }


  /**
   * Returns a ChangeEventGroup that would "redo" the appropriate undo-change in the puzzle's changeEventHistoryRef.current.
   * https://www.notion.so/Undo-Redo-4c6bb77444b1463e9fde459b521d121a for background and function logic.
   * @param {number} lastIndex The starting point in changeEventHistoryRef.current from which the function will walk backward recursively.
   * @returns {ChangeEventGroup?} The ChangeEventGroup that should be executed in order to perform the requested redo option, or null if it doesn't exist.
   */
  function redo(lastIndex) {
    if (lastIndex < 0 || lastIndex >= changeEventHistoryRef.current.length) return null;  // no event group to be found

    const lastEventGroup = changeEventHistoryRef.current[lastIndex];
    // TODO: Determine if additional event groups may be included as a major change to undo

    if (!lastEventGroup.isUndo && !lastEventGroup.isRedo) {
      return null;   // Redos are not allowed if the last ChangeEventGroup was a "natural" event.
    }

    var originalChangeEventGroupIndex;
    if (lastEventGroup.isUndo) {
      // Walk backward until the original ChangeEventGroup is found
      for (
        originalChangeEventGroupIndex = lastIndex; 
        changeEventHistoryRef.current[originalChangeEventGroupIndex].changeEventGroupId !== Math.min(...lastEventGroup.undoneChangeEventGroupIds);
        --originalChangeEventGroupIndex
      );  // upon exit of this loop, originalChangeEventGroupIndex is the minimum index in changeEventHistoryRef.current referencing an undone event
      
      return redoneChangeEventGroups([changeEventHistoryRef.current[originalChangeEventGroupIndex]]);
    }

    if (lastEventGroup.isRedo) { // i.e. else
      // Walk backward until the ChangeEventGroup that undid the same original event group as this Redo (NOT the original event group itself)
      for (
        originalChangeEventGroupIndex = lastIndex; 
        Math.min(...changeEventHistoryRef.current[originalChangeEventGroupIndex].undoneChangeEventGroupIds) !== Math.min(...lastEventGroup.redoneChangeEventGroupIds);
        --originalChangeEventGroupIndex
      );  // upon exit of this loop, originalChangeEventGroupIndex is the minimum index in changeEventHistoryRef.current referencing an undone event

      return redo(originalChangeEventGroupIndex - 1);
    }
  }

  /**
   * A special-case function capable of directly setting state values (setCharGrid, setSlotStructure, etc.),
   * intended to be called by requestUndo() or requestRedo().
   * This function serves as an alternative method of modifying state values to the setCharGridAndHandleAffairs(...) group of methods.
   * Because this is intended to be used by Undo / Redo, this also sets the cursorLoc / cursorDirection to the site of the change.
   * IMPORTANT: This function doesn't perform business-logic checks on the the given change events.
   * For instance, a grid change event will be accepted without looking for any necessary accompanying clue numbering changes.
   * https://www.notion.so/BoardInteractionContext-js-cadea7b0f5054ebbb532aec6eaf6e7f7#008e6f9c291b4760b9cfc45a28ecb56f for more.
   * @param {ChangeEventGroup} changeEventGroup 
   */
  function executeChangeEventGroup(changeEventGroup, options = {}) {
    const { updateCursor = true } = options;

    // Get new puzzle data with incorporated change events
    const newPuzzleData = mergePuzzleWithChangeEvents({ charGrid, furnishings, puzzleMetadata, clues }, changeEventGroup.changeEventList);
    const gridHasResized = charGrid.length !== newPuzzleData.charGrid.length || charGrid[0].length !== newPuzzleData.charGrid[0].length;

    // Set state values
    setCharGrid(newPuzzleData.charGrid);
    setFurnishings(newPuzzleData.furnishings);
    setClues(newPuzzleData.clues);
    setPuzzleMetadata(newPuzzleData.puzzleMetadata);

    // Update slotStructure, slotFillOptions, boardStyle, and ghostChars.
    /* Instead of optimizing this as in setCharGridAndHandleAffairs, I opt to just re-construct these each time to avoid extra logic.
     * If this becomes a bottleneck, I can reconsider. */
    const newSlotStructure = SlotStructure.buildNewSlotStructure(newPuzzleData.charGrid);
    setSlotStructure(newSlotStructure);
    let newSlotFillOptions = slotFillOptions;
    if (slotFillOptions !== null) {  // ensure word database has loaded
      newSlotFillOptions = getSlotFillOptions(newSlotStructure);
      setSlotFillOptions(newSlotFillOptions);
    }
    setBoardStyle(getBoardStyleFromSlotStructure(newSlotStructure, newSlotFillOptions, puzzlePreferences));

    tryContinuousAutofill(newSlotStructure);

    // Update cursor information if available
    if (updateCursor) {
      if (newPuzzleData.cursorLoc) {
        setCursorLoc(newPuzzleData.cursorLoc);
      }
      if (newPuzzleData.cursorDirection) {
        setCursorDirection(newPuzzleData.cursorDirection);
      }
    } else if (gridHasResized) {
      setCursorLoc(null);   // just extra protection against nasty index out of bounds errors
    }

    // Update word suggestor cache
    updateCharGridForWordSuggestor(newPuzzleData.charGrid, newSlotStructure, newSlotFillOptions);

    // Attach these change events to the changeEventHistoryRef and submit to remote collaborators
    submitChangeEvents(changeEventGroup);

    // Set isSaved to false
    setIsSaved(false);
    resetCurrentStatusMessage();
  }


  /**
   * Tries to "undo" the last change in the puzzle.
   * https://www.notion.so/Undo-Redo-4c6bb77444b1463e9fde459b521d121a
   */
  function requestUndo() {
    if (puzzleInteractionStatus === PuzzleInteractionStatus.STATUESQUE || puzzleInteractionStatus === PuzzleInteractionStatus.THINKING) return;

    const undoChangeEventGroup = undo(changeEventHistoryRef.current.length - 1);

    if (undoChangeEventGroup) {
      executeChangeEventGroup(undoChangeEventGroup);
    }
  }


  /**
   * Tries to "redo" the last change in the puzzle.
   * https://www.notion.so/Undo-Redo-4c6bb77444b1463e9fde459b521d121a
   */
  function requestRedo() {
    if (puzzleInteractionStatus === PuzzleInteractionStatus.STATUESQUE || puzzleInteractionStatus === PuzzleInteractionStatus.THINKING) return;

    const redoChangeEventGroup = redo(changeEventHistoryRef.current.length - 1);

    if (redoChangeEventGroup) {
      executeChangeEventGroup(redoChangeEventGroup);
    }
  }



  function turnOnContinuousAutofill() {
    if (!continuouslyAutofill) {
      setContinuouslyAutofill(true);
      turnOnWordSuggestor();
      
      if (!autofillStatus) {
        // Start an autofill attempt if there's no current attempt
        tryContinuousAutofill(slotStructure, true);
      }
    }
  }

  function turnOffContinuousAutofill() {
    if (continuouslyAutofill) {
      setContinuouslyAutofill(false);
      turnOffWordSuggestor();
      setSuggestedFill(null);
      cancelAutofill();
    }
  }


  /**
   * (1) Cancels any existing continuous-autofill attempts
   * (2) Clears any existing ghostChars
   * (3) Starts a new autofill search
   * Note that this function accepts a slotStructure as opposed to reading the state value,
   * because it may be called with a "new" slotStructure before the state value has been updated.
   * @param {SlotStructure} newSlotStructure
   * @param {boolean} overrideContinuousAutofill if true, will execute a continuous autofill attempt whether or not continuouslyAutofill is currently enabled
   * @param {number | undefined} supersedingMinScore if given, will be used as the most updated minScore (as opposed to that found in puzzlePreferences)
   * @param {boolean} triggerRender will pass on to useStateRef functions
   */
  function tryContinuousAutofill(newSlotStructure, overrideContinuousAutofill = false, supersedingMinScore = undefined, triggerRender = true) {

    // If continuousAutofill is not on (and we didn't just turn it on, as would be indicated by overrideContinuousAutofill), don't do anything more
    if (!continuouslyAutofill && !overrideContinuousAutofill) {
      setAutofillStatus(null, triggerRender);  // the grid was updated, so previous SUCCESS/FAILURE indicator is no longer accurate
      return;
    }

    // Clear existing ghostChars
    setSuggestedFill({ locsToChange: [], newChars: [], essentialLocs: [] });
      // Hmm... I suppose there's a potential minor issue in case the last autofill call returns after the ghostChars have been cleared (inserting more ghostChars) but before the last one has been stopped
      // But the other way we risk clearing ghostChars that are returned instantaneously by the new autofill call. It's pretty inconsequential so I'll leave it

    // If the grid is not worth autofilling, i.e. if it would be an impossible task, also don't bother
    if (!isGridWorthAutofilling(newSlotStructure)) {
      stopAutofillAndWordSuggestor();
      setAutofillStatus(null, triggerRender);
      return;
    }

    // (Otherwise, there's no need to stopAutofill, as it will be stopped by the next startAutofill call)

    if (!window.Worker) return;
    const onEssentialFill = (locsToChange, newChars) => {
      setSuggestedFill({ locsToChange, newChars, essentialLocs: locsToChange });
    };
    const onSuccess = (locsToChange, newChars, essentialLocs) => {
      // add some conditional about whether it's the same call ID... TODO... (other callbacks as well)
      setSuggestedFill({ locsToChange, newChars, essentialLocs });
      setAutofillStatus(AUTOFILL_STATUS.SUCCEEDED);

      // Also add this fill into the word suggestor so it can potentially bypass some of its word searching
      addKnownFillToWordsMap({ locsToChange, newChars, essentialLocs });

    };
    const onFailure = (message) => {
      setAutofillStatus(AUTOFILL_STATUS.FAILED);
      // Also stop the word suggestor
      declareFillImpossible();
    };

    const minScore = supersedingMinScore === undefined ? getPuzzlePreference(PuzzlePreferenceKey.MINIMUM_WORD_SCORE) : supersedingMinScore;
    startAutofill(newSlotStructure, onEssentialFill, onSuccess, onFailure, minScore);   // throttling logic occurs here
    setAutofillStatus(AUTOFILL_STATUS.SEARCHING, triggerRender);
  }


  /**
   * Called when the user specifically requests an autofill.
   * Sends a message to the autofill worker to start the autofill process.
   * Does not work if the grid is full (even with tentative things).
   */
  function requestAutofill() {
    if (continuouslyAutofill) return;   // don't call this if it's already continuously autofilling

    if (window.Worker) {
      if (puzzleInteractionStatus === PuzzleInteractionStatus.EDITABLE || puzzleInteractionStatus === PuzzleInteractionStatus.EDITABLE_GUEST) {
        if (charGrid.some(row => row.some(ch => ch === ''))) { // ensure that there's at least one empty square in the grid
          setAutofillStatus(AUTOFILL_STATUS.SEARCHING);
          setPuzzleInteractionStatus(PuzzleInteractionStatus.THINKING);
          const onEssentialFill = (locsToChange, newChars) => {
            setSuggestedFill({ locsToChange, newChars, essentialLocs: locsToChange });
          };
          const onSuccess = (locsToChange, newChars, essentialLocs) => {
            setSuggestedFill({ locsToChange, newChars, essentialLocs });
            setAutofillStatus(AUTOFILL_STATUS.SUCCEEDED);
            setPuzzleInteractionStatus(baselinePuzzleInteractionStatus);
          };
          const onFailure = (message) => {
            postErrorNotification('No autofill found!', 'Autofill failed: ' + message + '.', ['puzzle-specific'])
            declareFillImpossible();
            setAutofillStatus(AUTOFILL_STATUS.FAILED);
            setPuzzleInteractionStatus(baselinePuzzleInteractionStatus);
          };
          startAutofill(slotStructure, onEssentialFill, onSuccess, onFailure, getPuzzlePreference(PuzzlePreferenceKey.MINIMUM_WORD_SCORE));
        }
      }
    } else {
      postErrorNotification('Autofill not available!', 'It looks like you\'re using an older browser that doesn\'t support the necessary features. Try upgrading your browser!', ['puzzle-specific']);
    }
  }

  /**
   * Called when the user specifically asks to cancel a previously-requested autofill.
   * Terminates the current autofill operation (and the word suggestor as well).
   */
  function cancelAutofill() {
    stopAutofillAndWordSuggestor();
    if (autofillStatus === AUTOFILL_STATUS.SEARCHING) {
      setAutofillStatus(null);
    }
    setPuzzleInteractionStatus(baselinePuzzleInteractionStatus);
  }

  

  return (
    <PuzzleIdContext.Provider value={puzzleId}>
      <CursorContext.Provider value={{cursorLoc, cursorDirection, handleClickOnLoc, handleCursorDirective, currentSlotName}}>
        <CharGridContext.Provider value={charGrid}>
          <FurnishingsContext.Provider value={{furnishings, furnishingValueAt, updateColorFurnishing, removeColorFurnishing, toggleCircleFurnishingsAtLocs, makeOuterBlackoutsInvisible, removeAllInvisibilityFurnishings}}>
            <UpdateCharGridAtLocsContext.Provider value={updateCharGridAtLocs}>
              <ChangeCharGridDimensionsContext.Provider value={changeCharGridDimensions}>
                <SlotStructureContext.Provider value={slotStructure}>
                  <SlotFillOptionsContext.Provider value={slotFillOptions}>
                    <RequestNewBlackoutsContext.Provider value={requestNewBlackouts}>
                      <GhostCharsContext.Provider value={{ghostChars, acceptGhostChars, setSuggestedFill}}>
                        <AutofillContext.Provider value={{requestAutofill, cancelAutofill, autofillStatus, continuouslyAutofill, turnOnContinuousAutofill, turnOffContinuousAutofill}}>
                          <WordSuggestorContext.Provider value={{wordSuggestorCache, wordsMap, displayMoreWords, isCurrentlyEvaluatingMore, continuouslyAutofill}}>
                            <CluesContext.Provider value={{clues, setCluesAt}}>
                              <PuzzlePreferencesContext.Provider value={{getPuzzlePreference, setPuzzlePreference, turnOffWarnings, turnOnWarnings, areAnyWarningsOn, setMinScore}}>
                                <PuzzleMetadataContext.Provider value={{puzzleMetadata, setPuzzleMetavalue}}>
                                  <BoardStyleContext.Provider value={boardStyle}>
                                    <SavePuzzleContext.Provider value={{requestPuzzleSave, isSaving, isSaved}}>
                                      <PuzzleInteractionStatusContext.Provider value={{puzzleInteractionStatus, isScratch, currentStatusMessage}}>
                                        <PuzzleVersioningContext.Provider value={{requestUndo, requestRedo}}>
                                          <LiveModeContext.Provider value={{inLiveMode, liveModeIsConnecting, liveModeIsTimedOut, enterLiveMode, exitLiveMode, liveConnectionInfo}}>
                                            {!gridOnly && <Helmet>
                                              <title>{(isScratch ? 'Scratch Space' : (puzzleMetadata.get(PuzzleMetadataKey.TITLE) || 'Untitled')) + ' - Crossworthy Construct'}</title>
                                            </Helmet>}
                                            {children}
                                          </LiveModeContext.Provider>
                                        </PuzzleVersioningContext.Provider>
                                      </PuzzleInteractionStatusContext.Provider>
                                    </SavePuzzleContext.Provider>
                                  </BoardStyleContext.Provider>
                                </PuzzleMetadataContext.Provider>
                              </PuzzlePreferencesContext.Provider>
                            </CluesContext.Provider>
                          </WordSuggestorContext.Provider>
                        </AutofillContext.Provider>
                      </GhostCharsContext.Provider>
                    </RequestNewBlackoutsContext.Provider>
                  </SlotFillOptionsContext.Provider>
                </SlotStructureContext.Provider>
              </ChangeCharGridDimensionsContext.Provider>
            </UpdateCharGridAtLocsContext.Provider>
          </FurnishingsContext.Provider>
        </CharGridContext.Provider>
      </CursorContext.Provider>
    </PuzzleIdContext.Provider>
  );


}

