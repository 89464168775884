import React from 'react';
import { CursorDirective } from '../../libs/cursorLib';
import KeyboardEventHandler from '../../libs/keyboardLib';
import Board from '../board/Board';
import { PlayPreferenceKey, usePlayCursor, usePlayPreferences, usePlayPuzzleData, usePlayState } from './PlayInteractionContext';
import { BoardStyle, CellStyleName } from '../../libs/formatLib';
import { ACROSS, DOWN, getSlotName, otherDirection } from '../../libs/directionsLib';
import MobileKeyboard from '../reusable/MobileKeyboard';
import { extractClueCommands } from '../../libs/puzzleLib';
import ZoomableDiv from '../reusable/ZoomableDiv';
import { useAppContext } from '../../App';




/**
 * A wrapper around Board that adds interaction with PlayInteractionContext and a keyboard listener.
 * Also handles isolated zooming of the board component (e.g. if a mobile user pinches the board), and the display of the custom mobile keyboard.
 */
const PlayBoard = React.forwardRef(({...props}, ref) => {

  const { furnishings, clues, slotStructure } = usePlayPuzzleData();
  const { playStateCharGrid, addToPlayState, removeFromPlayState, gridIsFullAndCorrect, incorrectLocs, revealedLocs, revealLocs } = usePlayState();
  const { cursorLoc, cursorDirection, handleCursorDirective, handleClickOnLoc } = usePlayCursor();
  const { getPlayPreference } = usePlayPreferences();
  const { isMobile } = useAppContext();

  // Incorporate revealed answers, mistakes, and linked slots into board style
  const currentSlotName = cursorLoc ? slotStructure.getSlotNameContainingCoordinates(cursorLoc, cursorDirection) : null;
  var currentLinkedLocs = [];    // list of all the highlighted locs "linked" to the current clue (e.g. if the clue is like "see 2-Across")
  if (currentSlotName) {
    const currentClueText = clues.get(currentSlotName);
    currentLinkedLocs = slotStructure.getLocsInSlots(extractClueCommands(currentClueText, /^link:\d+[AD]$/).map(cc => {
      const match = /(\d+)([AD])/.exec(cc);
      return getSlotName(match[1], match[2] === 'A' ? ACROSS : DOWN);
    }));
  }

  const boardStyle = BoardStyle.default()
      .withAdditionalStyledLocs(getPlayPreference(PlayPreferenceKey.SHOW_MISTAKES_INSTANTLY) ? incorrectLocs : [], CellStyleName.MISTAKE)
      .withAdditionalStyledLocs(revealedLocs, CellStyleName.REVEALED)
      .withAdditionalStyledLocs(currentLinkedLocs, CellStyleName.POSITIVE);


  const board = <Board
    ref={ref}
    charGrid={playStateCharGrid}
    furnishings={furnishings}
    boardStyle={boardStyle}
    handleClickOnLoc={handleClickOnLoc}
    cursorLoc={cursorLoc}
    cursorDirection={cursorDirection}
    cursorColor='#0961ed'
    {...props}
  />;


  function handleKeyEvent(key, event) {
    if (event) event.preventDefault();

    if (cursorLoc) {

      // Alphabetic character: enter in cursor location and advance cursor
      if (/^[a-zA-Z]$/.test(key)) {
        if (!gridIsFullAndCorrect) {
          if (playStateCharGrid[cursorLoc[0]][cursorLoc[1]]) {
            // If it's being OVERWRITTEN, we just want to advance to the next (empty or nonempty) loc. If it's writing to an empty square, we want to go to the next empty loc.
            addToPlayState(cursorLoc, key.toUpperCase());
            handleCursorDirective(CursorDirective.NEXT_SQUARE_SAME_WORD);
          } else {
            addToPlayState(cursorLoc, key.toUpperCase());
            handleCursorDirective(CursorDirective.NEXT_OPEN_SQUARE_CLUEWISE);
          }
        }
      }

      else if (key === 'left') {
        if (cursorDirection === DOWN) {
          handleClickOnLoc(cursorLoc);    // just change directions, but stay on the same cursor
        } else {
          handleCursorDirective(CursorDirective.LEFT);
        }
      } else if (key === 'up') {
        if (cursorDirection === ACROSS) {
          handleClickOnLoc(cursorLoc);
        } else {
          handleCursorDirective(CursorDirective.UP);
        }
      } else if (key === 'right') {
        if (cursorDirection === DOWN) {
          handleClickOnLoc(cursorLoc);
        } else {
          handleCursorDirective(CursorDirective.RIGHT);
        }
      } else if (key === 'down') {
        if (cursorDirection === ACROSS) {
          handleClickOnLoc(cursorLoc);
        } else {
          handleCursorDirective(CursorDirective.DOWN);
        }
      }

      // Ctrl+arrow keys: move cursor but keep direction
      else if (key === 'ctrl+left' || key === 'meta+left' || key === 'shift+left') {
        handleCursorDirective(CursorDirective.LEFT_PRESERVE_DIRECTION);
      } else if (key === 'ctrl+up' || key === 'meta+up' || key === 'shift+up') {
        handleCursorDirective(CursorDirective.UP_PRESERVE_DIRECTION);
      } else if (key === 'ctrl+right' || key === 'meta+right' || key === 'shift+right') {
        handleCursorDirective(CursorDirective.RIGHT_PRESERVE_DIRECTION);
      } else if (key === 'ctrl+down' || key === 'meta+down' || key === 'shift+down') {
        handleCursorDirective(CursorDirective.DOWN_PRESERVE_DIRECTION);
      }

      // Space: advance to next cell without entering any letters
      else if (key === 'space') {
        handleCursorDirective(CursorDirective.NEXT_SQUARE);
      }

      // Backspace
      else if (key === 'backspace') {
        if (!gridIsFullAndCorrect) {
          if (playStateCharGrid[cursorLoc[0]][cursorLoc[1]] !== '') {
            removeFromPlayState(cursorLoc);
          }
          handleCursorDirective(CursorDirective.PREVIOUS_SQUARE_SAME_WORD);
        }
      }
      else if (key === 'del') {
        if (!gridIsFullAndCorrect) {
          removeFromPlayState(cursorLoc);
        }
      }

      // Tab, enter, shift-tab, and shift-enter should take you to the first open one in the next word
      else if (key === 'tab' || key === 'enter') {
        handleCursorDirective(CursorDirective.NEXT_WORD);
      } else if (key === 'shift+tab' || key === 'shift+enter') {
        handleCursorDirective(CursorDirective.PREVIOUS_WORD);
      }

      // Escape
      else if (key === 'esc') {
        handleClickOnLoc(null);
      }
    }
  }



  return (
    <>
      {currentSlotName && <MobileKeyboard
        currentSlotName={currentSlotName}
        showCurrentClue
        clues={clues}
        layoutName='play'
        onKeyPress={key => {
          if (key === '{hint}') {
            revealLocs([cursorLoc]);
            handleCursorDirective(CursorDirective.NEXT_SQUARE_SAME_WORD);
          } else if (key === '{rotate}') {
            handleClickOnLoc(cursorLoc, otherDirection(cursorDirection));
          } else {
            handleKeyEvent(key.toLowerCase());
          } 
        }}
      />}

      <KeyboardEventHandler
        handleKeys={[
          'alphabetic',
          'left', 'up', 'right', 'down',
          'ctrl+left', 'meta+left', 'ctrl+right', 'meta+right',
          'ctrl+up', 'meta+up', 'ctrl+down', 'meta+down',
          'shift+left', 'shift+up', 'shift+down', 'shift+right',
          'space', 'tab', 'shift+tab', 'enter', 'shift+enter',
          'backspace', 'del',
          'esc',
        ]}
        onKeyEvent={handleKeyEvent}
      />

      <ZoomableDiv>
        {board}
        {isMobile && <div className='text-center text-muted small fst-italic mt-3'>Pinch board to zoom!</div>}
      </ZoomableDiv>
    </>
  );
});

export default PlayBoard;