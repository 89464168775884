import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, OverlayTrigger, Popover, ProgressBar, Tooltip } from 'react-bootstrap';
import { BiCheck, BiLoaderAlt, BiMinus, BiX } from 'react-icons/bi';
import { MdOutlineAutoFixHigh } from 'react-icons/md';
import { SketchPicker } from 'react-color';
import { AUTOFILL_STATUS, WORD_SUGGESTION_STATUS, wordScoreFrequencies } from '../../libs/autofillLib';
import { PuzzlePreferenceKey, useAutofill, useCursor, useFurnishings, usePuzzlePreferences, useWordSuggestor } from './BoardInteractionContext';
import CluesPanel from './CluesPanel';
import WordSuggestorPanel from './WordSuggestorPanel';
import { AiOutlineBgColors } from 'react-icons/ai';
import { furnishingsObjectToList, FurnishingType } from '../../libs/furnishingsLib';
import { FaRegCircle } from 'react-icons/fa';
import { TbCircleDotted } from 'react-icons/tb';
import { useAppContext } from '../../App';
import KeyboardEventHandler from '../../libs/keyboardLib';


export const TAB_KEY = {
  FILL: 'fill',
  CLUES: 'clues',
};

const DEFAULT_COLORS = ['transparent', '#ff5827', '#f5a623', '#f8e71c', '#7ed321', '#00fffb', '#ff51f8', '#a891eb'];




function MinScoreSlider({ currentMinScore, setMinScore, ...props }) {

  const [displayedSliderValue, setDisplayedSliderValue] = useState(currentMinScore);   // this is used to decouple the slider with the actual minScore for debouncing purposes
  useEffect(() => setDisplayedSliderValue(currentMinScore), [currentMinScore]);   // but we DO want currentMinScore changes to propagate to displayedSliderValue
  const sliderTimeout = useRef(null);
  function onSliderChange(newValue) {
    // Set displayed slider value immediately
    setDisplayedSliderValue(newValue);
    // Throttle upward calls to setMinScore by a whole second
    clearTimeout(sliderTimeout.current);
    sliderTimeout.current = setTimeout(() => setMinScore(newValue), 1000);
  }

  if (!wordScoreFrequencies) return <div {...props}><div className='fst-italic text-muted small text-center'>Word database not loaded.</div></div>;

  const minValue = Math.min(...wordScoreFrequencies.keys());
  const maxValue = Math.max(...wordScoreFrequencies.keys());

  // We can divide up the range into rectangles that span from one score value to the next (like a Riemann sum!)
  const bounds = (Array.from(wordScoreFrequencies.entries())
    .sort((a, b) => a[0] - b[0])       // sort by increasing score value
    .map(([scoreValue, _], idx, arr) => ({
      cumulativeNumWords: arr.filter(e => e[0] >= scoreValue).reduce((acc, cur) =>  acc + cur[1], 0),
      lowBoundScore: scoreValue,
      highBoundScore: arr[idx+1]?.[0],
    }))
  );

  const VIEWBOX_HEIGHT = 30;
  const TOTAL_NUM_WORDS = Math.max(...bounds.map(o => o.cumulativeNumWords));
  const HEIGHT_SCALE = VIEWBOX_HEIGHT / TOTAL_NUM_WORDS * 0.9;
  const VIEWBOX_WIDTH_PADDING = (maxValue - minValue) * 0.075;

  const rects = bounds.map(({ cumulativeNumWords, lowBoundScore, highBoundScore }) => (
    <rect
      x={lowBoundScore + VIEWBOX_WIDTH_PADDING}
      width={highBoundScore ? highBoundScore - lowBoundScore : VIEWBOX_WIDTH_PADDING}
      y={VIEWBOX_HEIGHT - HEIGHT_SCALE*cumulativeNumWords}
      height={VIEWBOX_HEIGHT}
      fill={lowBoundScore >= displayedSliderValue ? '#42d1fc' : 'lightgrey'}
      key={`minscore-reimann-${lowBoundScore}`}
    />
  )).concat([
    <rect
      x={0}
      width={VIEWBOX_WIDTH_PADDING}
      y={VIEWBOX_HEIGHT - HEIGHT_SCALE*TOTAL_NUM_WORDS}
      height={VIEWBOX_HEIGHT}
      fill={'lightgrey'}
      key='minscore-last-reimann'
    />
  ]);

  return (
    <div {...props}>
      <svg viewBox={`0 0 ${maxValue - minValue + 2*VIEWBOX_WIDTH_PADDING} ${VIEWBOX_HEIGHT}`}>
        {rects}
        <line
          x1={displayedSliderValue + VIEWBOX_WIDTH_PADDING}
          y1={0}
          x2={displayedSliderValue + VIEWBOX_WIDTH_PADDING}
          y2={VIEWBOX_HEIGHT}
          stroke='blue'
          strokeWidth={1}
        />
      </svg>
      <Form.Range
        id='min-word-score-slider'
        min={minValue}
        max={maxValue}
        step={1}
        value={displayedSliderValue}
        onChange={e => onSliderChange(parseInt(e.target.value))}
      />
      <div className='text-muted text-center small fst-italic'>
        {Array.from(wordScoreFrequencies.entries()).filter(o => o[0] >= displayedSliderValue).reduce((acc, cur) => acc + cur[1], 0).toLocaleString()} words &ge; {displayedSliderValue}.
      </div>
    </div>
  );
}






const ConstructorSidePanel = React.forwardRef(({ tabKey, setTabKey, pxHeight=400, ...props }, ref) => {

  const [currentColor, setCurrentColor] = useState('#F5A623');
  const [isHoveringOverAutofillToolbar, setIsHoveringOverAutofillToolbar] = useState(false);

  const { windowWidth } = useAppContext();
  const { getPuzzlePreference, setMinScore } = usePuzzlePreferences();
  const { turnOnContinuousAutofill, turnOffContinuousAutofill, autofillStatus, continuouslyAutofill } = useAutofill();
  const { cursorLoc } = useCursor();
  const { furnishings, furnishingValueAt, updateColorFurnishing, removeColorFurnishing, toggleCircleFurnishingsAtLocs } = useFurnishings();
  const { wordsMap, isCurrentlyEvaluatingMore } = useWordSuggestor();

  var numWordsWithFill = 0, numWordsWithoutFill = 0, numWordsNotSure = 0;
  if (wordsMap) {
    wordsMap.forEach(value => {
      if (value.status === WORD_SUGGESTION_STATUS.FILLABLE) {
        ++numWordsWithFill;
      } else if (value.status === WORD_SUGGESTION_STATUS.UNFILLABLE) {
        ++numWordsWithoutFill;
      } else if (value.status === WORD_SUGGESTION_STATUS.NOT_EVALUATED || value.status === WORD_SUGGESTION_STATUS.TIMEOUT) {
        ++numWordsNotSure;    // this leaves out the intentionally ignored words (words with too low scores)
      }
    });
  }
  const totalConsideredWords = numWordsWithoutFill + numWordsWithFill + numWordsNotSure;  // leaves out ignored words

  /**
   * Deals with "transparent" color by removing furnishing for the cursor cell, if cursorLoc is defined.
   * Does not do anything if there is already an equivalent color furnishing on the cursor loc.
   */
  function updateFurnishingColorForCursorLoc(newColorHex) {
    if (cursorLoc) {
      if (newColorHex === 'transparent' && furnishingValueAt(cursorLoc, FurnishingType.COLOR)) {
        removeColorFurnishing(cursorLoc);
      } else if (furnishingValueAt(cursorLoc, FurnishingType.COLOR) !== newColorHex) {
        updateColorFurnishing(newColorHex, cursorLoc);
      }
    }
  }

  const headerRow = tabKey === TAB_KEY.FILL ? (
    <div className='d-flex mb-2'>

      <div
        id='autofill-toolbar'
        className='d-flex me-2'
        onMouseEnter={() => setIsHoveringOverAutofillToolbar(true)}
        onMouseLeave={() => setIsHoveringOverAutofillToolbar(false)}
        style={{ width: '200px' }}
      >

        {!continuouslyAutofill ? (
          <Button
            className='w-100'
            size='sm'
            variant='outline-success'
            onClick={() => {
              turnOnContinuousAutofill();
              setIsHoveringOverAutofillToolbar(false);
            }}
          >
            <MdOutlineAutoFixHigh className='me-1' />
            Turn on autofiller
          </Button>
        ) : (
          isHoveringOverAutofillToolbar ? (
            <Button
              className='w-100'
              size='sm'
              variant='info'
              onClick={() => turnOffContinuousAutofill()}
            >Turn off autofiller</Button>
          ) : (
            <>
            
              {!autofillStatus ? <BiMinus className='my-auto ms-1' /> : 
                autofillStatus === AUTOFILL_STATUS.SEARCHING ? <BiLoaderAlt className='spinning my-auto ms-1' /> :
                autofillStatus === AUTOFILL_STATUS.SUCCEEDED ? <BiCheck className='my-auto ms-1 text-success' /> :
                <BiX className='my-auto ms-1 text-danger' /> }


              {wordsMap ? (
                <div className='mx-2 my-auto text-center small' style={{width: '10rem'}}>
                  <MdOutlineAutoFixHigh className='text-muted me-1' />&#183;
                  <span className='text-success mx-1'>{numWordsWithFill}</span>&#183;
                  <span className='text-danger mx-1'>{numWordsWithoutFill}</span>&#183;
                  <span className='text-secondary ms-1'>{numWordsNotSure}</span>
                  <ProgressBar style={{height: '0.75rem'}}>
                    <ProgressBar
                      className='m-0'
                      variant='success'
                      striped={isCurrentlyEvaluatingMore}
                      animated={isCurrentlyEvaluatingMore}
                      now={numWordsWithFill ? numWordsWithFill / totalConsideredWords * 100 : 0}
                      key={1}
                      style={{transition: 'none'}}
                    />
                    <ProgressBar
                      variant='danger'
                      striped={isCurrentlyEvaluatingMore}
                      animated={isCurrentlyEvaluatingMore}
                      now={numWordsWithoutFill ? numWordsWithoutFill / totalConsideredWords * 100 : 0}
                      key={2}
                      style={{transition: 'none'}}
                    />
                  </ProgressBar>
                </div>
              ) : (
                <span className='small m-auto text-secondary fst-italic'>Select a cell for suggestions</span>
              )}
            </>
          )
        )}
      </div>

      <OverlayTrigger
        trigger='click'
        rootClose
        placement='bottom'
        overlay={<Popover>
          <MinScoreSlider className='m-2' currentMinScore={getPuzzlePreference(PuzzlePreferenceKey.MINIMUM_WORD_SCORE)} setMinScore={setMinScore} />
        </Popover>}
      >
        <Button
          variant='outline-secondary'
          size='sm'
          style={{ borderColor: 'darkgrey' }}
        >&ge; {getPuzzlePreference(PuzzlePreferenceKey.MINIMUM_WORD_SCORE)}</Button>
      </OverlayTrigger>
      



      <OverlayTrigger
        trigger='click'
        rootClose
        placement='bottom'
        overlay={<Popover>
          <SketchPicker
            color={currentColor}
            onChange={newColor => {
              setCurrentColor(newColor.hex);
              updateFurnishingColorForCursorLoc(newColor.hex);
            }}
            disableAlpha
            presetColors={DEFAULT_COLORS
              .concat(furnishingsObjectToList(furnishings).filter(f => f.furnishingType === FurnishingType.COLOR).map(f => f.value.toLowerCase())) // always show default colors first
              .filter((val, idx, arr) => arr.indexOf(val) === idx)    // unique colors only
            }
          />
        </Popover>}
      >
        <Button
          className='d-flex ms-2'
          onClick={() => {
            updateFurnishingColorForCursorLoc(currentColor);
          }}
          style={{ color: 'black', backgroundColor: currentColor, borderColor: 'darkgrey' }}
        >
          <AiOutlineBgColors className='my-auto' />
        </Button>
      </OverlayTrigger>


      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement='bottom'
        overlay={<Tooltip>{furnishingValueAt(cursorLoc, FurnishingType.SHAPE) ? 'Remove' : 'Add a'} circle</Tooltip>}
      >
        <Button
          className='d-flex ms-2 p-1'
          variant='outline-secondary'
          disabled={!cursorLoc}
          onClick={() => {
            toggleCircleFurnishingsAtLocs([cursorLoc], !furnishingValueAt(cursorLoc, FurnishingType.SHAPE))
          }}
        >
          {furnishingValueAt(cursorLoc, FurnishingType.SHAPE) ? <TbCircleDotted className='my-auto' /> : <FaRegCircle className='my-auto' />}
        </Button>
      </OverlayTrigger>



      <div className='d-flex ms-auto'>
        <Button
          className='zoomable'
          variant='success'
          size='sm'
          onClick={() => setTabKey(TAB_KEY.CLUES)}
        >{windowWidth >= 992 && 'Show '}Clues</Button>
      </div>
    </div>
  ) : (
    <div className='d-flex mb-2'>
      <Button
        className='ms-auto zoomable'
        variant='info'
        size='sm'
        onClick={() => setTabKey(TAB_KEY.FILL)}
      >Show Fill Suggestions</Button>
    </div>
  );


  return (
    <div className='ms-3' ref={ref} {...props}>

      {headerRow}

      {tabKey === TAB_KEY.FILL ? <WordSuggestorPanel pxHeight={pxHeight} /> : <CluesPanel pxHeight={pxHeight} />}

      <KeyboardEventHandler
        handleKeys={[
          'ctrl+shift+f', 'meta+shift+f',
        ]}
        onKeyEvent={(key, event) => {
          event.preventDefault();
          console.log('x');
          updateFurnishingColorForCursorLoc(currentColor);
        }}
      />
    </div>
  );


});

export default ConstructorSidePanel;