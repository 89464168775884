import React, { useCallback, useEffect, useState, useContext, createContext } from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import Button from 'react-bootstrap/Button';
import './App.css';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { LinkContainer } from "react-router-bootstrap";
import { fetchAuthSession } from 'aws-amplify/auth';
import { MdAccountCircle, MdOutlineAccountCircle } from 'react-icons/md';
import { useWindowDimensions } from './libs/hooksLib';
import ErrorCatcher from './ErrorCatcher';
import { logout } from './libs/authLib';
import { ToastQueue } from './libs/toastLib';
import AccountModal from './AccountModal';
import { SPACES } from './libs/miscLib';
import pinkBlob from './assets/pink-blob.png';



const AppContext = createContext(null);

export function useAppContext() {
  return useContext(AppContext);
}


function App() {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();


  // Window size
  const { windowWidth, windowHeight } = useWindowDimensions();


  // Handle authentication and logout

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      const tokens = (await fetchAuthSession()).tokens;
      if (tokens) {
        userHasAuthenticated(true);
      }
    } catch(e) {
      console.log('Error fetching current auth session');
    }

    setIsAuthenticating(false);
  }

  function handleLogout(onLogout) {
    logout({ onSuccess: () => {
      userHasAuthenticated(false);
      if (onLogout) onLogout();
    } });
  }

  // The website exists across two "spaces": the normal "Crossworthy" and "Crossworthy Construct"
  const currentSpace = (
    location.pathname === '/' || location.pathname === '/about' ? SPACES.SPLASH :
    location.pathname.startsWith('/play') || location.pathname.startsWith('/collections') ? SPACES.PLAY :
    location.pathname.startsWith('/construct') || location.pathname.startsWith('/manage-collections') ? SPACES.CONSTRUCT :
    SPACES.INTERSTITIAL
  );

  // Title and meta tags (mostly to update the document title, and provide descriptions for Googlebot)
  /* Note: 
  I've set this up as a bare-bones "catch-all" helmet, and it's not intended to cover specific bases.
  If there's a good reason, child components can and should override this helmet anytime by simply including their own helmet. 
  For instance, the "About" and "Splash" page components have their own helmets with better descriptions for those pages.
  */

  const generalHelmet = currentSpace === SPACES.CONSTRUCT ? (
    <Helmet>
      <title>Crossworthy Construct</title>
      <meta name='description' content='Make your own crosswords with easy collaboration, sharing, and fill assistance!' />
    </Helmet>
  ) : currentSpace === SPACES.PLAY ? (
    <Helmet>
      <title>Crossworthy Play</title>
      <meta name='description' content='Solve indie crosswords on Crossworthy.' />
    </Helmet>
  ) : (
    <Helmet>
      <title>Crossworthy Crosswords</title>
      <meta name='description' content='Make, share, and solve crossword puzzles, with easy collaboration and fill assistance. A free and indie site!' />
    </Helmet>
  );


  const [brandIsSquished, setBrandIsSquished] = useState(false);   // whether or not the full "Crossworthy" text is displayed or just the logo on the splash screen
  const brandIsShowing = windowWidth > 660;    // whether or not the brand logo is showing at all (not on small screens)

  const brand = (
    <LinkContainer to={currentSpace === SPACES.CONSTRUCT && isAuthenticated ? '/construct/f' : '/'}>
      <div role='button'>
        {currentSpace === SPACES.SPLASH && windowWidth > 660 ? (
          <div className={brandIsSquished ? 'fade-invisible' : 'fade-visible'}>
            <img
              className='zoomable'
              style={{ position: 'fixed', zIndex: 210, top: '-40px', left: '-40px' }}
              width={170}
              src={pinkBlob}
              alt='pink-blob'
            />
            <img
              className='zoomable'
              style={{ position: 'fixed', zIndex: 211, top: 14, left: 14 }}
              width={72}
              src='/android-chrome-256x256.png'
              alt='Crossworthy'
            />
          </div>
        ) : (
          <>
            <div
              className={currentSpace === SPACES.PLAY || (currentSpace === SPACES.SPLASH && windowWidth <= 660) ? 'teal-3-bkgd' : 'purple-3-bkgd'}
              style={{ position: 'fixed', zIndex: 210, top: '-10px', left: '-10px', height: '70px', width: '70px', borderRadius: '50%', boxShadow: '0 0 5px #301934' }}
            />
            <img
              className='zoomable'
              style={{ position: 'fixed', zIndex: 211, top: '0', left: '0' }}
              width={54}
              src='/android-chrome-256x256.png'
              alt='Crossworthy'
            />
          </>
        )}
        {/*currentSpace === SPACES.SPLASH && windowWidth > 660 && <>
          <span className={`block-text ${brandIsSquished ? 'animated-squished-left' : ''}`} style={{ fontSize: 24, position: 'fixed', top: '0px', left: '67px', zIndex: 99999 }}>Crossworthy</span>
          <span className={brandIsSquished ? 'animated-squished-right' : ''} style={{ fontSize: 16, position: 'fixed', top: '25px', left: '88px', zIndex: 99999 }}>by May and Kevin</span>
        </>*/}
      </div>
    </LinkContainer>
  );

  const settingsButton = (
    <div>
      {currentSpace === SPACES.SPLASH && windowWidth > 660 ? (
        <div className={'d-flex gap-2 p-1 ' + (brandIsSquished ? 'fade-invisible' : 'fade-visible')} style={{ position: 'fixed', zIndex: 200, top: '20px', right: '35px', borderRadius: '20px' }}>
          {isAuthenticated ? (
            <Button
              size='sm'
              style={{ borderRadius: '12px' }}
              variant='secondary'
              href='/construct/f'
              onClick={e => {
                e.preventDefault();
                navigate('/construct/f');
              }}
            >My constructions</Button>
          ) : (
            <>
              <Button
                size='sm'
                style={{ borderRadius: '12px' }}
                variant='secondary'
                onClick={e => {
                  e.preventDefault();
                  setShowSettingsModal(true);
                }}
              >Login/Signup</Button>
            </>
          )}
          {isAuthenticated && <>
            <Button
              size='sm'
              style={{ borderRadius: '12px' }}
              variant='secondary'
              onClick={e => {
                e.preventDefault();
                setShowSettingsModal(true);
              }}
            >Account</Button>
          </>}
          <Button
            size='sm'
            style={{ borderRadius: '12px' }}
            variant='secondary'
            href='/about'
            onClick={e => {
              e.preventDefault();
              navigate('/about');
            }}
          >About Us</Button>
        </div>

      ) : (

        <div role='button' onClick={() => setShowSettingsModal(s => !s)}>
          <div
            className='grey-1-bkgd'
            style={{ position: 'fixed', zIndex: 200, top: '-8px', right: '-5px', height: '50px', width: '50px', borderRadius: '50%', boxShadow: '0 0 5px #3A3B3C' }}
          />
          {isAuthenticated ? (
            <MdAccountCircle
              className='zoomable'
              size={25}
              style={{ position: 'fixed', zIndex: 201, top: '5px', right: '7px' }}
            />
          ) : (
            <MdOutlineAccountCircle
              className='zoomable'
              size={25}
              style={{ position: 'fixed', zIndex: 201, top: '5px', right: '7px' }}
            />
          )}
        </div>

      )}
    </div>
  );

  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const settingsModal = <AccountModal show={showSettingsModal} onHide={() => setShowSettingsModal(false)} currentSpace={currentSpace} />;
  const showLoginPage = useCallback(() => setShowSettingsModal(true), []);   // memoization is necessary to avoid infinite render loops (if the function is used in a child component's useEffect)



  const [bottomPadding, setBottomPadding] = useState(0);   // padding added e.g. if mobile keyboard is showing



  return (
    !isAuthenticating && (
      <HelmetProvider>
        <AppContext.Provider value={{
          windowWidth, windowHeight,
          isMobile: windowWidth < 660 || windowHeight < 500,
          isAuthenticated, userHasAuthenticated, handleLogout, showLoginPage,
          displaySettingsModal: () => setShowSettingsModal(true),
          brandIsShowing, setBrandIsSquished,
          setBottomPadding, bottomPadding,
        }}>
          <ToastQueue>
            <ErrorCatcher>
              {generalHelmet}
              {brandIsShowing && brand}
              {settingsButton}
              {settingsModal}

              <Outlet />
            </ErrorCatcher>
          </ToastQueue>

          <div
            id='bottom-spacer'
            style={{ height: bottomPadding, visibility: 'hidden' }}
          />

        </AppContext.Provider>
      </HelmetProvider>
    )
  );
}

export default App;
