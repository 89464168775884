import React, { useRef, useState } from "react";


const ANIM_DURATION = 300;   // duration of the snap-back transition animations when the user scrolls or zooms beyond the board


/**
 * Component that allows isolated zooming of just its children on mobile devices using standard gestures (pinch, drag).
 * Introduced as a way for players to zoom in on the board without zooming in on the keyboard or other components.
 */
export default function ZoomableDiv({ width='100%', height='100%', children, ...props }) {
  const containerRef = useRef(null);
  const [transform, setTransform] = useState({
    scale: 1,
    translateX: 0,
    translateY: 0,
  });

  const [lastTouch, setLastTouch] = useState(null);
  const [isAnimating, setIsAnimating] = useState(false); // Track animation state

  const handleTouchStart = (e) => {
    if (isAnimating) return; // Prevent interaction during animation

    if (e.touches.length === 2) {
      // Handle pinch zoom
      const [touch1, touch2] = e.touches;
      const distance = Math.hypot(
        touch2.clientX - touch1.clientX,
        touch2.clientY - touch1.clientY
      );
      setLastTouch({ distance, scale: transform.scale });
    } else if (e.touches.length === 1) {
      // Handle panning
      const touch = e.touches[0];
      setLastTouch({
        x: touch.clientX,
        y: touch.clientY,
        translateX: transform.translateX,
        translateY: transform.translateY,
      });
    }
  };

  const handleTouchMove = (e) => {
    if (isAnimating) return; // Prevent interaction during animation

    if (e.touches.length === 2 && lastTouch?.distance) {
      // Pinch zoom
      const [touch1, touch2] = e.touches;
      const newDistance = Math.hypot(
        touch2.clientX - touch1.clientX,
        touch2.clientY - touch1.clientY
      );
      const scaleChange = newDistance / lastTouch.distance;

      setTransform((prev) => ({
        ...prev,
        scale: Math.min(Math.max(lastTouch.scale * scaleChange, 0.5), 3), // Limit zoom scale
      }));
    } else if (e.touches.length === 1 && lastTouch?.x != null) {
      // Pan
      const touch = e.touches[0];
      const deltaX = (touch.clientX - lastTouch.x) / transform.scale;
      const deltaY = (touch.clientY - lastTouch.y) / transform.scale;

      // Apply edge constraints
      setTransform((prev) => {
        return {
          ...prev,
          translateX: lastTouch.translateX + deltaX,
          translateY: lastTouch.translateY + deltaY,
        };
      });
    }
  };

  const handleTouchEnd = () => {

    // Calculate max boundaries
    const container = containerRef.current;
    const containerWidth = container.offsetWidth;
    const containerHeight = container.offsetHeight;
    const scaledWidth = containerWidth * transform.scale;
    const scaledHeight = containerHeight * transform.scale;
    const maxTranslateX = (scaledWidth - containerWidth) / 2 / transform.scale;
    const maxTranslateY = (scaledHeight - containerHeight) / 2 / transform.scale;

    
    if (transform.scale < 1.1) {
      // Animate back to 100% zoom and reset position
      setIsAnimating(true);
      setTransform({
        scale: 1,
        translateX: 0,
        translateY: 0,
      });

      // Disable animation after it completes
      setTimeout(() => setIsAnimating(false), ANIM_DURATION); // Match transition duration
    } else if (Math.abs(transform.translateX) > maxTranslateX || Math.abs(transform.translateY) > maxTranslateY) {
      // Animate back to within x, y boundaries
      setIsAnimating(true);
      setTransform({
        ...transform,
        translateX: transform.translateX < 0 ? Math.max(-maxTranslateX, transform.translateX) : Math.min(maxTranslateX, transform.translateX),
        translateY: transform.translateY < 0 ? Math.max(-maxTranslateY, transform.translateY) : Math.min(maxTranslateY, transform.translateY),
      });
      setTimeout(() => setIsAnimating(false), ANIM_DURATION);
    }

    setLastTouch(null);
  };

  return (
    <div
      ref={containerRef}
      style={{
        width,
        height,
        overflow: "hidden",
        border: transform.scale > 1 ? '1px solid black' : 'none',
        touchAction: "none", // Disable default gestures like scroll
        position: "relative",
      }}
      {...props}
    >
      <div
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        style={{
          width: "100%",
          height: "100%",
          transform: `scale(${transform.scale}) translate(${transform.translateX}px, ${transform.translateY}px)`,
          transformOrigin: "center",
          transition: isAnimating ? `transform ${ANIM_DURATION / 1000}s ease` : "none", // Smooth transition
        }}
      >
        {children}
      </div>
    </div>
  );
}


