import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Col, Container, Row } from 'react-bootstrap';
import { FaInstagram, FaXTwitter } from 'react-icons/fa6'
import mayvin from '../components/reusable/mayvin.png';
import yellowBubble from '../assets/yellow-bubble.png';
import orangeBubble from '../assets/orange-bubble.png';
import { useNavigate, Link } from 'react-router-dom';
import { useAppContext } from '../App';

export default function About() {

  const navigate = useNavigate();
  const { windowWidth } = useAppContext();
  const isSquished = windowWidth < 900;

  return (
    <div className='d-flex justify-content-center p-4 graph-paper-bkgd' style={{
      minHeight: '100vh',
    }}>
      <Helmet>
        <title>About Crossworthy</title>
        <meta name='description' content='Meet May and Kevin, the crossword enthusiasts who put together Crossworthy.' />
      </Helmet>

      <Container style={{ marginTop: isSquished ? '50px' : '150px', maxWidth: '1000px', height: '100%' }}>

        {isSquished && <Row className='text-center balanced-text block-text mb-5 d-flex flex-column'>
          <span className='h3 mx-auto' style={{ maxWidth: '400px' }}>Hello! We are May and Kevin at Crossworthy.</span>
          <img className='mx-auto my-4' src={mayvin} alt='May and Kevin' style={{ width: '50%' }} />
          <span>Check out our story in <a href='https://www.nytimes.com/2022/03/24/crosswords/all-in-the-game-of-love.html' target='_blank' rel='noreferrer'>The New York Times</a>!</span>
        </Row>}
   
        <Row>
          {!isSquished && <Col className='my-auto' xs={4}>
            <img
              className='zoomable clickable'
              src={yellowBubble}
              alt='crossworthy-in-new-york-times'
              width='240px'
              style={{ position: 'absolute', marginTop: '-80px', marginLeft: '96px', rotate: '2deg' }}
              onClick={() => window.open('https://www.nytimes.com/2022/03/24/crosswords/all-in-the-game-of-love.html', '_blank')}
            />
            <img src={mayvin} alt='May and Kevin' width='100%' />
            <img
              className='zoomable clickable'
              src={orangeBubble}
              alt='give-feedback'
              width='200px'
              style={{ marginTop: '-210px', marginLeft: '10px' }}
              onClick={() => navigate('/feedback')}
            />
          </Col>}

          <Col className='mt-3' xs={isSquished ? 12 : 8}>
            <p>
              <strong>Kevin</strong> is a hobbyist web developer who first created Crossworthy to show off May's puzzles, and then to help others like her get into constructing too.
              He also enjoys constructing the odd playful crossword, which you can usually find published here on Crossworthy.
              By day, he works at a biotech startup doing some really fun science.
            </p>
            <p>
              <strong>May</strong> started making crosswords during the pandemic and to this day continues to love how these word puzzles surprise, challenge, and delight.
              May's puzzles have been published in Apple News, USA Today, Universal, LA Times, Inkubator, LA Times, and elsewhere.
              She loves putting pop culture references in her puzzles! She works in PR and also translates literature from Hong Kong and Taiwan.
            </p>


            <div className='fw-bold'>Follow us on Instagram or Twitter!</div>
            <div className={'d-flex ' + (isSquished ? 'flex-column' : '')}>
              <div className='me-4' style={{ whiteSpace: 'nowrap' }}>
                <FaInstagram className='me-1' size={20} />
                <Link to='https://www.instagram.com/crossworthy.dev/'>@crossworthy.dev</Link>
              </div>
              <div style={{ whiteSpace: 'nowrap' }}>
                <FaXTwitter className='me-1' size={20} />
                <Link to='https://twitter.com/CrossWorthy'>crossworthy</Link>
              </div>
            </div>
          </Col>

        </Row>
      
      </Container>
    </div>
  );
}